import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Table, Typography, Grid, Paper, TextField, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, Menu, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Accordion = () => {
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDeleteOpen, setDeleteDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phonenumber: '',
    alterphonenumber: '',
    gstnumber: '',
    aadharnumber: '',
    pannumber: '',
    membership: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    pincode: '',
    state: '',
  });
  const [msgOpen, setMsgOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpenUserId, setMenuOpenUserId] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch('/api/userdetails');
      const result = await response.json();
      setData(result.data || []);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserById = async (userId) => {
    try {
      const response = await fetch(`/api/useradmin?id=${userId}`);
      const result = await response.json();
      setUser(result.data);
      setFormData({
        name: result.data.username || '',
        email: result.data.email || '',
        phonenumber: result.data.phonenumber || '',
        alterphonenumber: result.data.alterphonenumber || '',
        gstnumber: result.data.gstnumber || '',
        aadharnumber: result.data.aadharnumber || '',
        pannumber: result.data.pannumber || '',
        membership: result.data.membership || '',
        addressLine1: result.data.addressline1 || '',
        addressLine2: result.data.addressline2 || '',
        city: result.data.city || '',
        pincode: result.data.pincode || '',
        state: result.data.state || '',
      });
    } catch (error) {
      console.error('Error fetching user by ID:', error);
    }
  };

  const updateUser = async (userId) => {
    try {
      const response = await fetch(`/api/user_update?id=${userId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      setMsg(result.message || 'Update successful');
      setMsgOpen(true);
      setEditDialogOpen(false);
      fetchUserData();
    } catch (error) {
      console.error('Error updating user:', error);
      setMsg('Error updating user');
      setMsgOpen(true);
    }
  };

  const userDelete = async (userId) => {
    try {
      const response = await fetch(`/api/userdelete?id=${userId}`, {
        method: 'DELETE',
      });
      const result = await response.json();
      setMsg(result.message || 'Delete successful');
      setMsgOpen(true);
      setDeleteDialogOpen(false);
      fetchUserData();
    } catch (error) {
      console.error('Error deleting user:', error);
      setMsg('Error deleting user');
      setMsgOpen(true);
    }
  };

  const handleMenuOpen = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenUserId(userId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpenUserId(null);
  };

  const handleEditClick = (userId) => {
    setEditDialogOpen(true);
    fetchUserById(userId);
  };

  const handleDeleteOpen = (userId) => {
    setDeleteDialogOpen(true);
    fetchUserById(userId);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    if (user) {
      userDelete(user._id);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell align="center">Phone Number</StyledTableCell>
              <StyledTableCell align="center">Mail</StyledTableCell>
              <StyledTableCell align="center">City</StyledTableCell>
              <StyledTableCell align="center">Membership</StyledTableCell>
              <StyledTableCell align="center">Details</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => item.role === 'businessman' && (
                <StyledTableRow key={index}>
                  <StyledTableCell>{item.username}</StyledTableCell>
                  <StyledTableCell align="center">{item.phonenumber}</StyledTableCell>
                  <StyledTableCell align="center">{item.email}</StyledTableCell>
                  <StyledTableCell align="center">{item.city}</StyledTableCell>
                  <StyledTableCell align="center">{item.membership}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton onClick={() => { setDialogOpen(true); fetchUserById(item._id); }}>
                      <InfoIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton onClick={(e) => handleMenuOpen(e, item._id)}>
                      <EditIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && menuOpenUserId === item._id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleEditClick(item._id)}>Edit</MenuItem>
                      <MenuItem onClick={() => handleDeleteOpen(item._id)}>Delete</MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <Typography>No data available</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} TransitionComponent={Transition}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          {user && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Name: {user.username}</Typography>
                <Typography>Phone: {user.phonenumber}</Typography>
                <Typography>Email: {user.email}</Typography>
                <Typography>Address: {user.addressline1}, {user.addressline2}</Typography>
                <Typography>City: {user.city}</Typography>
                <Typography>Pincode: {user.pincode}</Typography>
                <Typography>Aadhar Number: {user.aadharnumber}</Typography>
                <Typography>Pan Number: {user.pannumber}</Typography>
                <Typography>Membership: {user.membership}</Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} TransitionComponent={Transition}>
        <DialogTitle>Manage User Profile</DialogTitle>
        <DialogContent>
          <Grid container>
            {Object.keys(formData).map(key => (
              <Grid item xs={12} key={key}>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  fullWidth
                  margin="normal"
                  name={key}
                  value={formData[key]}
                  onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => { updateUser(user._id); }}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDeleteOpen} onClose={handleDeleteClose} TransitionComponent={Transition}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={msgOpen} autoHideDuration={6000} onClose={() => setMsgOpen(false)}>
        <Alert onClose={() => setMsgOpen(false)} severity="info" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Accordion;
