import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import '@coreui/coreui/dist/css/coreui.min.css'
import { CCarousel, CImage, CCarouselItem } from '@coreui/react';
// import Paper from '@mui/material/Paper';
import NavBar from '../navbar/index';
import Foot from '../footer/index'
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Container, Grid, Typography, Button,IconButton,InputBase,Paper, } from '@mui/material';
// import { AiOutlineTable } from "react-icons/ai";
import SearchIcon from '@mui/icons-material/Search';
// import AcUnitIcon from '@mui/icons-material/AcUnit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMedical,faBed,faCouch,faSnowman,faSpa,faHotel,faMasksTheater,faUtensils } from '@fortawesome/free-solid-svg-icons';
import { TbAirConditioning } from "react-icons/tb";
import { MdApartment } from "react-icons/md";
import { FaMasksTheater } from "react-icons/fa6";
import { GiNecklaceDisplay } from "react-icons/gi";
import { FaUtensils } from "react-icons/fa6";
import { FaSpa } from "react-icons/fa";
import { FaHotel } from "react-icons/fa6";
import { GiPartyFlags } from "react-icons/gi";
import { FaCouch } from "react-icons/fa6";
import { GiAmpleDress } from "react-icons/gi";
import { PiStudentFill } from "react-icons/pi";
import { GiBunkBeds } from "react-icons/gi";
import { GiLovers } from "react-icons/gi";
import { FaTruckPlane } from "react-icons/fa6";
import { CgMoreVerticalO } from "react-icons/cg";
import { IoStorefrontOutline } from "react-icons/io5";
import { GiWeightLiftingUp } from "react-icons/gi";
import { FaAmbulance } from "react-icons/fa";
import { GrCloudSoftware } from "react-icons/gr";


import Ambulance from '../../assets/qik call mobile view icons/ambulance1.png'
import Homeapp from '../../assets/qik call mobile view icons/electric-appliance1.png'
// import Hostel from '../../assets/qik call mobile view icons/pop.png'
import Furniture from '../../assets/qik call mobile view icons/furniture1.png'
import Homedec from '../../assets/qik call mobile view icons/strawberry1.png'
import Beauty from '../../assets/qik call mobile view icons/io.png'
import Restaraunts from '../../assets/qik call mobile view icons/strawberry1-1.png'
import Hotel from '../../assets/qik call mobile view icons/strawberry2.png'
import Theatre from '../../assets/qik call mobile view icons/theater1.png'
import Jewellery from '../../assets/qik call mobile view icons/jewelry1.png'
import Apartments from '../../assets/qik call mobile view icons/apartment1.png'
import AC from '../../assets/qik call mobile view icons/air-conditioner1.png'
import Dress from '../../assets/qik call mobile view icons/lol.png'
import Computer from '../../assets/img/computer.png'
import Dentist from '../../assets/img/dentist.png'
import Law from '../../assets/img/law.png'
import Car from '../../assets/img/maintenance.png'
import Event from '../../assets/img/organiser.png'
import Photo from '../../assets/img/photographer.png'
import Electric from '../../assets/img/electrician.png'
import Transport from '../../assets/img/delivery-truck.png'
import More from '../../assets/img/plus.png'

import Images1 from '../../assets/img/images.jpeg'
import Images2 from '../../assets/img/images2.jpeg'
import Packers from '../../assets/img/packers.jpg'
import Packers1 from '../../assets/img/packers2.webp'
import Packers3 from '../../assets/img/packers3.avif'
import Spa1 from '../../assets/img/spa1.avif'
import Spa3 from '../../assets/img/spa3.webp'
import Spa4 from '../../assets/img/spa4.avif'
import Services1 from '../../assets/img/service1.webp'
import Services2 from '../../assets/img/services2.avif'
import Services3 from '../../assets/img/services3.webp'
import Estate1 from '../../assets/img/realestate1.jpg'
import Estate2 from '../../assets/img/realestate2.jpg'
import Estate3 from '../../assets/img/realestate3.avif'
import Lawyer1 from '../../assets/img/lawyer1.jpg'
import Lawyer2 from '../../assets/img/lawyer2.jpg'
import Carpenter1 from '../../assets/img/carpenter1.jpg'
import Carpenter2 from '../../assets/img/carpenter2.jpg'
import Banner from '../../assets/img/Sale1.jpg'
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';




const DownCard = (props) =>{
  const names = Array(24).fill('Ambulance Service'); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return(
    <>
    {isMobile ? (
    <>
     <Typography variant="h5"  className=" mt-1 mb-2 title-mob">{props.mobtitle}</Typography>

<Grid className="frequent-categories-container" >
  <Grid container className="categories-list-mob">
    {names.map((category, index) => (
      <Grid key={index} className="category-item-mob">
        <Typography variant='subtitle1' sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} fontSize={"8px"}>• {category}</Typography>
      </Grid>
    ))}
  </Grid>

</Grid>
    </>
  ): (
  <>
  <Grid id="box-width">
            <Typography variant="h4"  className="title mt-5 mb-2">{props.title}</Typography>
            </Grid>
            <Grid xs={12} className="frequent-categories-container" >
              <Grid container className="categories-list">
                {names.map((category, index) => (
                  <Grid key={index} className="category-item">
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", fontSize: "10px" }}>• {category}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
  </>
)}
    </>
  )
}



const MidCategories =()=>{

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const navigate = useNavigate();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [getData, setGetData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    GetCategory();
  }, []);

  const GetCategory = () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch("/api/categorylist", requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.status === 400) {
            return { status_code: response.status, data: await response.json() };
          } else {
            return { status_code: response.status, data: { msg: 'Unexpected Error' } };
          }
        })
        .then((result) => {
          setGetData(result.data.data);
        })
        .catch(error => console.log('error', error));
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigate = (category) => {
    navigate(`/categorieslist/${category}`);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const categoriesDialog = [
    { 
      icon:  <FaAmbulance style={{ color: "#fa0000",fontSize: "30px" }} />, label: 'Ambulance'  },
    { icon: <GrCloudSoftware  style={{color: "#74C0FC",fontSize: "30px"}} />, label: 'Home Appliances' },
    { icon: <FaHotel style={{color: "#992e00",fontSize: "30px"}} />, label: 'Furniture' },
    { icon: <FaUtensils  style={{color: "#005eff",fontSize: "30px"}} />, label: 'Home Decor' },
    { icon: <FaMasksTheater  style={{color: "#B197FC",fontSize: "30px"}} />, label: 'Beauty SPA' },
    { icon: < GiNecklaceDisplay style={{color: "#500075",fontSize: "30px"}} />, label: 'Restaurants' },
    { icon:  < GiPartyFlags style={{color: "#b69e00",fontSize: "30px"}} />, label: 'Hotel' },
    { icon: <  FaCouch style={{color: "#500075",fontSize: "30px"}} />, label: 'Theaters' },
    { icon: < FaSpa style={{ color: "#a43663", fontSize: "30px" }}  />, label: 'Jewellery Shop' },
    { icon: <MdApartment style={{ color: "#800000", fontSize: "30px" }} />, label: 'Apartments' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'AC Service' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Dress Shop' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Computer repair' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Dentist' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Lawyer' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Car Servie' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Event Manangement' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Photographer' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Electrician' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'Transportation' },
    // { img: <img src={Dentist} alt="Computer" style={{ width: isMobile ? "50px" : "60px", height: isMobile ? "40px" : "60px" }} />, label: 'Computer repair' },


  ];
  

 
  
  const categories = [
    { 
      icon:  <FaTruckPlane style={{ color: "#fa0000",fontSize: "30px" }} />, label: 'Packer & Movers'  },
    { icon: <GrCloudSoftware  style={{color: "#74C0FC",fontSize: "30px"}} />, label: 'Software' },
    { icon: <FaHotel style={{color: "#992e00",fontSize: "30px"}} />, label: 'Hotel' },
    { icon: <FaUtensils  style={{color: "#005eff",fontSize: "30px"}} />, label: 'Restarunt' },
    { icon: <FaMasksTheater  style={{color: "#B197FC",fontSize: "30px"}} />, label: 'Theater' },
    { icon: < GiNecklaceDisplay style={{color: "#500075",fontSize: "30px"}} />, label: 'Jwelery Shop' },
    { icon:  < GiPartyFlags style={{color: "#b69e00",fontSize: "30px"}} />, label: 'Event Organisers' },
    { icon: <  FaCouch style={{color: "#500075",fontSize: "30px"}} />, label: 'Furniture' },
    { icon: < FaSpa style={{ color: "#a43663", fontSize: "30px" }}  />, label: 'Beauty SPA' },
    { icon: <TbAirConditioning style={{ color: "#800000", fontSize: "30px" }} />, label: 'AC Service' },
    { icon: <GiAmpleDress style={{ color: "#8f87ff", fontSize: "30px" }} />, label: 'Dress Shop' },
    { icon: <PiStudentFill style={{ color: "#8f87ff", fontSize: "30px" }} />, label: 'Education' },
    { icon: <GiBunkBeds style={{ color: "#8f87ff", fontSize: "30px" }} />, label: 'PG/Hostel' },
    { icon: <GiLovers style={{ color: "#8f87ff", fontSize: "30px" }} />, label: 'Wedding Planning' },
    { icon: <GiWeightLiftingUp style={{ color: "#8f87ff", fontSize: "30px" }} />, label: 'Gym' },
    { 
      icon: <CgMoreVerticalO style={{ color: "#8f87ff", fontSize: "30px" }} />, 
      label: 'More',
      onClick: handleClickOpen
    }
    
    // { img: <img src={Dentist} alt="Computer" style={{ width: isMobile ? "50px" : "60px", height: isMobile ? "40px" : "60px" }} />, label: 'Computer repair' },


  ];
  return(
    <>
   {isMobile ? (
   <>
  
            <Box sx={{ flexGrow: 1, backgroundColor: '#f7f4cd', padding: '20px' }}>
              <Grid container  spacing={3}>
                {categories.map((category, index) => (
                  <Grid item xs={3} sm={3} md={2} key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '5px',
                        borderColor:"#00bfff",
                        backgroundColor: '#00bfff',
                        borderRadius: '8px',
                        color: '#fff',
                      }}
                      
                    >
                      {category.icon}
                    </Box>
                    <Typography variant="body1" sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", marginTop: '10px', textAlign: "left", fontSize:"10px" }}>
                      {category.label}
                    </Typography>

                  </Grid>
                ))}
              </Grid>
              
            </Box>
   </>
  ):(
  <>
   <Typography variant="h4" className="title mt-5" > Categories</Typography>
            <Box mt={4} mb={4} sx={{ flexGrow: 1, backgroundColor: '#f7f4cd', padding: '20px' }}>
              <Grid container     spacing={3}>
                {categories.map((category, index) => (
                  <Grid item container direction="column" justifyContent="space-evenly" alignItems="center" xs={2} sm={2} md={1.5} key={index}>
                    <Paper
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80px',
    height: '80px',
    padding: '10px',
    border: "1px solid #00bfff",
    borderRadius: '8px',
    color: '#fff',
    backgroundColor:"#00bfff"
    
  }}
  onClick={category.onClick }
>
  {category.icon}
</Paper>
                    <Typography variant="body1" sx={{ fontSize:"13px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", marginTop: '10px',justifyContent:"center", textAlign: "center" }}>
                      {category.label}
                    </Typography>

                  </Grid>
                ))}
              </Grid>
              <Dialog
    maxWidth="xl"
    open={open}
    onClose={handleClose}
    aria-labelledby="responsive-dialog-title"
    sx={{
      '& .MuiDialog-paper': {
        margin: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        // transform: 'translateY(-50%)',
      },
    }}
  >
    <DialogTitle id="responsive-dialog-title">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid xs={6} container direction="row" alignItems="center">
          <Grid>
            <IconButton
              edge="center"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid>
            <Typography sx={{ fontSize: "18px" }}>Popular Categories</Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}  container direction='column' justifyContent="flex-end" alignItems="flex-end">
<Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%", height: "40px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", '&:hover': { border: "2px solid #2d2859" } }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
      <Grid mt={3} container spacing={2} justifyContent="space-evenly">
  {categoriesDialog.map((category, index) => (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={index}
    >
      {getData[index] && (
        <Paper
          elevation={2}
          sx={{ display: 'flex', alignItems: 'center', padding: '10px', width: '100%' }}
        >
          <Grid  sx={{ marginRight: '12px' }}>
            {category.icon}
          </Grid>

          <Typography onClick={(e)=>{handleNavigate(getData[index]._id)}} sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            {getData[index].categoryname}
          </Typography>
        </Paper>
      )}
    </Grid>
  ))}
</Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
            </Box>
  </>
)}
    </>
  )
}













// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#00bfff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center'
// }));

function HomePage() {

  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 1
    }
  };


  return (
    <>

      {isMobile ? (
        <>
          <NavBar />
          <Container>
            <Grid container direction="row" justifyContent="center" alignItems="center" mt={3} >
              <Grid item xs={12} >
              <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  infinite={true}
                  showDots={true}
                  transitionDuration={500}
                  removeArrowOnDeviceType={["tablet", "mobile"]}

                >
                  <div><img src={Banner} alt="Banner 1" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 2" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 3" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 4" className="carousel-image-mob" /></div>
                </Carousel>

              </Grid>
            </Grid>
          </Container>
          <Container  >
            <Grid container xs={12} sm={10} direction="row" mt={3}>
            <Typography variant="h5"  className="mb-3 title-mob">Recent Categories</Typography>
              <Grid xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" mb={2} >
                  <Grid item xs={5} >
                    <Grid  container direction="row" justifyContent="center" alignItems="flex-start" sx={{ backgroundColor: "#00bfff", height: "250px", borderRadius: "0px 0px 10px 10px" }}>
                      <Box  display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Packers} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Packers3} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Packers} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>


                        <Typography sx={{ color: "#000", fontSize: "15px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} className='mt-3'><b>Hotels</b></Typography>
                        <Button sx={{ backgroundColor: "#f7f4cd", color: "#000", fontSize: "10px", fontFamily: "Anton, sans-serif", fontStyle: "italic", '&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className=' mt-3 mb-2'>
                          Call Now
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} >
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ backgroundColor: "#00bfff", height: "250px", borderRadius: "0px 0px 10px 10px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Services1} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Services2} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Services3} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>


                        <Typography sx={{ color: "#000", fontSize: "15px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} className='mt-3'><b>Repairs & Services</b></Typography>
                        <Button sx={{ backgroundColor: "#f7f4cd", color: "#000", fontSize: "10px", fontFamily: "Anton, sans-serif", fontStyle: "italic",'&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className='mt-3 mb-2'>
                          Call Now
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" mb={2} >
                  <Grid item xs={5} >
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ backgroundColor: "#00bfff", height: "250px", borderRadius: "0px 0px 10px 10px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Carpenter1} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Carpenter2} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Carpenter1} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>


                        <Typography sx={{ color: "#000", fontSize: "15px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} className='mt-3'><b>Carpenters</b></Typography>
                        <Button sx={{ backgroundColor: "#f7f4cd", color: "#000", fontSize: "10px", fontFamily: "Anton, sans-serif", fontStyle: "italic",'&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className=' mt-3 mb-2'>
                          Call Now
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} >
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ backgroundColor: "#00bfff", height: "250px", borderRadius: "0px 0px 10px 10px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Lawyer1} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Lawyer2} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Lawyer1} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>


                        <Typography sx={{ color: "#000", fontSize: "15px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} className='mt-3'><b>Lawyers</b></Typography>
                        <Button sx={{ backgroundColor: "#f7f4cd", color: "#000", fontSize: "10px", fontFamily: "Anton, sans-serif", fontStyle: "italic",'&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className=' mt-3 mb-2'>
                          Call Now
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container>
            <Grid container direction="row" justifyContent="center" alignItems="center" mt={3} >
              <Grid item xs={12} >
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  infinite={true}
                  showDots={true}
                  transitionDuration={500}
                  removeArrowOnDeviceType={["tablet", "mobile"]}

                >
                  <div><img src={Banner} alt="Banner 1" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 2" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 3" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 4" className="carousel-image-mob" /></div>
                </Carousel>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="1200px" >
          <Typography variant="h5"  className=" mt-3 title-mob">Major Categories</Typography>
            <Box sx={{ flexGrow: 1, padding: 2 }} mt={3}>
            
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img src={Images1} alt="Appliances" style={{ width: '100%', height: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <img src={Images2} alt="Dining Room" style={{ width: '100%', height: "100%" }} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img src={Images1} alt="Appliances" style={{ width: '100%', height: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <img src={Images2} alt="Dining Room" style={{ width: '100%', height: "100%" }} />
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Container>
            <Grid container direction="row" justifyContent="center" alignItems="center" mt={3} >
              <Grid item xs={12} >
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  infinite={true}
                  showDots={true}
                  transitionDuration={500}
                  removeArrowOnDeviceType={["tablet", "mobile"]}

                >
                  <div><img src={Banner} alt="Banner 1" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 2" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 3" className="carousel-image-mob" /></div>
                  <div><img src={Banner} alt="Banner 4" className="carousel-image-mob" /></div>
                </Carousel>
              </Grid>
            </Grid>
          </Container>

          <Container  >
            <Typography variant="h5"  className="mt-4 title-mob" > Categories</Typography>
            <Grid container direction="row" justifyContent="center" alignItems="center" mt={3} >
              <Grid item xs={12} container direction="row" justifyContent="space-around" alignItems="center" >
                <Grid>
                  <MidCategories/>
                </Grid>
              </Grid>
            </Grid>
          </Container>



          <Container  >
            <Grid container xs={12} direction="row" mt={3} mb={3}>
              <Typography variant="h5"  className=" mb-4 title-mob">Daily Needs</Typography>
              <Grid xs={12}>
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={5}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Packers} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Packers3} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Packers} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", fontSize: "12px" }} className="mt-3">
                        Packers & Movers
                      </Typography>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "8px" }} variant="body1" className="mt-3" align="center">
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Spa1} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Spa4} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Spa1} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", fontSize: "12px" }} className="mt-3">
                        Spa & Saloon
                      </Typography>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "8px" }} variant="body1" className="mt-3" align="center">
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

              </Grid>
              <Grid xs={12} mt={2}>
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={5}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Services1} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Services2} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Services1} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", fontSize: "12px" }} className="mt-3">
                        Repairs & Services
                      </Typography>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "8px" }} variant="body1" className="mt-3" align="center">
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '150px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Estate1} alt="slide 1" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Estate2} alt="slide 2" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Estate1} alt="slide 3" style={{ height: '150px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", fontSize: "12px" }} className="mt-3">
                        Real Estate Agent
                      </Typography>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "8px" }} variant="body1" className="mt-3" align="center">
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Container>
           
          <Container >
            <DownCard mobtitle="Frequent Categories"/>
          </Container>

          <Container >
          <DownCard mobtitle="Major Cities in Tamilnadu"/>
          </Container>

          <Foot />
        </>
      ) : (
        <>
          <NavBar />
          <Container >
            <Grid container direction="row" spacing={3} mt={5}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={6} >
                    <Carousel
                      responsive={responsive}
                      autoPlay={true}
                      autoPlaySpeed={3000}
                      infinite={true}
                      showDots={true}
                      transitionDuration={500}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                      <div><img src={Banner} alt="Banner 1" className="carousel-image" /></div>
                      <div><img src={Banner} alt="Banner 2" className="carousel-image" /></div>
                      <div><img src={Banner} alt="Banner 3" className="carousel-image" /></div>
                      <div><img src={Banner} alt="Banner 4" className="carousel-image" /></div>
                    </Carousel>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={0} sx={{ flexDirection: 'column' }}>
                      <Grid item xs={12} md={12} sm={6} sx={{ padding: "5px" }}>
                        <Carousel
                          responsive={responsive}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          infinite={true}
                          width='1000%'
                          showDots={true}
                          transitionDuration={500}
                          removeArrowOnDeviceType={["tablet", "mobile"]}

                        >
                          <div><img src={Services1} alt="Banner 1" className="image" /></div>
                          <div><img src={Services3} alt="Banner 1" className="image" /></div>
                        </Carousel>
                      </Grid>
                      <Grid item xs={12} md={12} sm={6} sx={{ padding: "5px" }}>
                        <Carousel
                          responsive={responsive}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          infinite={true}
                          showDots={true}
                          transitionDuration={500}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                          <div><img src={Spa1} alt="Banner 1" className="image" /></div>
                          <div><img src={Spa3} alt="Banner 1" className="image" /></div>
                        </Carousel>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container  mt={5}>
            <MidCategories/>
           {/* <Grid ml={3} mt={1} mb={2} >
           <Button onClick={handleClickOpen} variant='outlined' sx={{fontSize:"15px",
      color:"#2d2859",
      borderColor: "#2d2859"
    }}>
            <img src={More} alt="more" width="30px" style={{ marginRight: "10px" }}/> More
            </Button>
            <Grid>
  
</Grid>
           </Grid> */}
          </Container>
          <Container  >
            <Box sx={{ flexGrow: 1, padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img src={Images1} alt="Appliances" style={{ width: '100%', height: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <img src={Images2} alt="Dining Room" style={{ width: '100%', height: "100%" }} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img src={Images1} alt="Appliances" style={{ width: '100%', height: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <img src={Images2} alt="Dining Room" style={{ width: '100%', height: "100%" }} />
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Container  >
            <Grid container xs={12} direction="row" ml={1} mt={10}>
              <Typography variant="h4"  className="title mb-5">Major Categories</Typography>
              <Grid xs={12}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item xs={2.5}>
                    <Grid sx={{ backgroundColor: "#00bfff", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "400px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '240px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Packers} alt="slide 1" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Packers3} alt="slide 2" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Packers} alt="slide 3" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>
                      </Box>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000" }} variant='h6' className='mt-4'>Packers & Movers</Typography>
                      <Button sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", backgroundColor: "#f7f4cd", color: "#000", '&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className='mt-4 mb-5'>
                        Call Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid sx={{ backgroundColor: "#00bfff", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "400px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '240px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Services1} alt="slide 1" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Services2} alt="slide 2" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Services3} alt="slide 3" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>
                      </Box>

                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000" }} variant='h6' className='mt-4'> Repair and Services</Typography>
                      <Button sx={{  fontFamily: "Anton, sans-serif", fontStyle: "italic", backgroundColor: "#f7f4cd", color: "#000",  '&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className="mt-4 mb-5">                        Call Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid sx={{ backgroundColor: "#00bfff", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "400px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '240px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Lawyer2} alt="slide 1" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Lawyer1} alt="slide 2" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Lawyer2} alt="slide 3" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>
                      </Box>
                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000" }} variant='h6' className='mt-4'>Lawyers</Typography>
                      <Button sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", backgroundColor: "#f7f4cd", color: "#000", '&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className='mt-4 mb-5'>
                        Call Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid sx={{ backgroundColor: "#00bfff", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "400px" }}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel controls transition="crossfade" style={{ height: '240px' }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Carpenter2} alt="slide 1" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Carpenter1} alt="slide 2" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Carpenter2} alt="slide 3" style={{ height: '240px', objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>
                      </Box>

                      <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000" }} variant='h6' className='mt-4'>Jewellery</Typography>
                      <Button sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", backgroundColor: "#f7f4cd", color: "#000", '&:hover': {  backgroundColor: "#e0e0e0" } }} variant="outlined" className='mt-4 mb-5'>
                        Call Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>


          <Container  >
            <Grid container direction="row" ml={1} mt={10} mb={3}>
              <Typography variant="h4"  className="title mb-5">Daily Needs</Typography>
              <Grid xs={12} container direction="row" justifyContent="space-around" alignItems="center">
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={2.5} container direction="row" alignItems="center" justifyContent="center" >
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '200px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Packers} alt="slide 1" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Packers3} alt="slide 2" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Packers1} alt="slide 3" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>
                    </Box>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", textAlign: "center" }} variant="h6" className="mt-2">
                      Packers & Movers
                    </Typography>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "10px", textAlign: "center" }} variant="body1" className="mt-3" align="center">
                      If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                    </Typography>

                  </Grid>
                  <Grid item xs={2.5} container direction="row" alignItems="center" justifyContent="center" >
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '200px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Spa1} alt="slide 1" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Spa3} alt="slide 2" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Spa4} alt="slide 3" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>

                    </Box>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", textAlign: "center" }} variant="h6" className="mt-2">
                      Spa & Saloon
                    </Typography>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "10px", textAlign: "center" }} variant="body1" className="mt-3" align="center">
                      If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                    </Typography>

                  </Grid>
                  <Grid item xs={2.5}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '200px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Services1} alt="slide 1" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Services2} alt="slide 2" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Services3} alt="slide 3" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>
                    </Box>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", textAlign: "center" }} variant="h6" className="mt-2">
                      Repairs and Service
                    </Typography>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "10px", textAlign: "center" }} variant="body1" className="mt-3" align="center">
                      If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                    </Typography>

                  </Grid>
                  <Grid item xs={2.5}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <CCarousel controls transition="crossfade" style={{ height: '200px' }}>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Estate1} alt="slide 1" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Estate2} alt="slide 2" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                        <CCarouselItem>
                          <CImage className="d-block w-100" src={Estate3} alt="slide 3" style={{ height: '200px', objectFit: 'cover' }} />
                        </CCarouselItem>
                      </CCarousel>

                    </Box>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000", textAlign: "center" }} variant="h6" className="mt-2">
                      Real Estate Agent
                    </Typography>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontStyle: "italic", color: "#000", fontSize: "10px", textAlign: "center" }} variant="body1" className="mt-3" align="center">
                      If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                    </Typography>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          {/* <Container >
          <DownCard title="Frequent Categories"/>
          </Container>

          <Container >
          <DownCard title="Major Cities in Tamil Nadu"/>
          </Container> */}

          <Foot />
        </>
      )}


    </>
  );
}

export default HomePage;
