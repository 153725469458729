import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {Table,Typography,Grid,Card,CardMedia,Alert,Snackbar,TextField }from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Accordion = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [user, setUser] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);
  const [color, setColor] = useState('');
  const [msg, setMsg] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await fetch('/api/userdetails');
      const result = await response.json();
      if (response.status === 200 || response.status === 400) {
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const getDataById = async (userId) => {
    try {
      const response = await fetch(`/api/useradmin?id=${userId}`);
      const result = await response.json();
      if (response.status === 200 || response.status === 400) {
        setUser(result.data);
        setName(result.data.username || '');
        setEmail(result.data.email || '');
        setPhonenumber(result.data.phonenumber || '');
        setAddressLine1(result.data.addressline1 || '');
        setAddressLine2(result.data.addressline2 || '');
        setCity(result.data.city || '');
        setPincode(result.data.pincode || '');
        setState(result.data.state || '');
      }
    } catch (error) {
      console.error('Error fetching user by ID:', error);
    }
  };

  const EditUser = async (userId) => {
    try {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        username: name || user.username,
        email: email || user.email,
        phonenumber: phonenumber || user.phonenumber,
        city: city || user.city,
        state: state || user.state,
        pincode: pincode || user.pincode,
        addressline1: addressLine1 || user.addressline1,
        addressline2: addressLine2 || user.addressline2,
      });

      const response = await fetch(`/api/user_update?id=${userId}`, {
        method: "PUT",
        headers: headers,
        body: raw
      });
      const result = await response.json();

      if (response.status === 200 || response.status === 400) {
        setMsg('User updated successfully');
        setColor('success');
      } else {
        setMsg('Update failed');
        setColor('error');
      }
      setMsgOpen(true);
      getUserData(); // Refresh data after update
    } catch (error) {
      console.error('Error updating user:', error);
      setMsg('Update failed');
      setColor('error');
      setMsgOpen(true);
    }
  };

  const handleClickOpen = (userId) => {
    setOpen(true);
    getDataById(userId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = (userId) => {
    setEdit(true);
    getDataById(userId);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handleSubmit = () => {
    EditUser(user._id);
    setEdit(false);
    window.location.reload()
  };

  const handleSnackClose = () => {
    setMsgOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (userId) => {
    try {
      await fetch(`/api/user_delete?id=${userId}`, { method: 'DELETE' });
      getUserData(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell align="center">Phone Number</StyledTableCell>
              <StyledTableCell align="center">Mail</StyledTableCell>
              <StyledTableCell align="center">City</StyledTableCell>
              <StyledTableCell align="center">Details</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => (
                item.role === "customer" && (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {item.username}
                    </StyledTableCell>
                    <StyledTableCell align="center">{item.phonenumber}</StyledTableCell>
                    <StyledTableCell align="center">{item.email}</StyledTableCell>
                    <StyledTableCell align="center">{item.city}</StyledTableCell>
                    <StyledTableCell align="center">
                      <InfoIcon onClick={() => handleClickOpen(item._id)} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton onClick={() => handleEditOpen(item._id)}>
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              ))
            ) : (
              <Typography variant="h6" align="center">
                No results found for customers.
              </Typography>
            )}
          </TableBody>
        </Table>

        {/* Details Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"User Details"}
          </DialogTitle>
          <DialogContent sx={{ width: "500px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "15px" }}>Name:</Typography>
                <Typography sx={{ fontSize: "15px" }}>Phone:</Typography>
                <Typography sx={{ fontSize: "15px" }}>Email:</Typography>
                <Typography sx={{ fontSize: "15px" }}>Address:</Typography>
                <Typography sx={{ fontSize: "15px" }}>City:</Typography>
                <Typography sx={{ fontSize: "15px" }}>Pincode:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "15px" }}>{user.username}</Typography>
                <Typography sx={{ fontSize: "15px" }}>{user.phonenumber}</Typography>
                <Typography sx={{ fontSize: "15px" }}>{user.email}</Typography>
                <Typography sx={{ fontSize: "15px" }}>{user.addressline1}, {user.addressline2}</Typography>
                <Typography sx={{ fontSize: "15px" }}>{user.city}, {user.state}, {user.pincode}</Typography>
                <Typography sx={{ fontSize: "15px" }}>{user.pincode}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog
          open={edit}
          onClose={handleEditClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Edit User"}
          </DialogTitle>
          <DialogContent sx={{ width: "500px" }}>
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address Line 1"
                  variant="outlined"
                  fullWidth
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address Line 2"
                  variant="outlined"
                  fullWidth
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Save</Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={msgOpen} autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity={color}>
            {msg}
          </Alert>
        </Snackbar>
      </TableContainer>
    </>
  );
};

export default Accordion;