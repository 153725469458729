import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocationOn, Phone, CheckCircle, FavoriteBorder } from '@mui/icons-material';
import { Container } from 'react-bootstrap';
import { CCarousel, CImage, CCarouselItem } from '@coreui/react';
import Sales from '../../assets/img/Sale1.jpg'
import NavBar from '../navbar/index';
import Foot from '../footer/index';
import Footer from '../footer/index';


// HotelCard Component
const HotelCard = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <>
    {isMobile ? (
    <>
    <Card
  sx={{
    display: 'flex',
    backgroundColor: '#ffffe0',
    borderRadius: '15px',
    padding: '16px',
    maxWidth: 400,
    flexDirection: { xs: 'column', sm: 'row' }, // Column on small screens, row on larger screens
  }}
>
  {/* Image */}
  <CardMedia
    component="img"
    sx={{
      width: { xs: '100%', sm: 150 }, // Full width on small screens, fixed width on larger screens
      borderRadius: '10px',
      marginBottom: { xs: '16px', sm: 0 }, // Add margin-bottom on small screens
    }}
    image="your-image-url-here"
    alt="Hotel"
  />

  {/* Content */}
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: { xs: 0, sm: '16px' }, // Remove margin on small screens
      textAlign: { xs: 'center', sm: 'left' }, // Center text on small screens
    }}
  >
    <CardContent
      sx={{
        flex: '1 0 auto',
        paddingBottom: '8px',
      }}
    >
      {/* Star Rating */}
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center' }}>
        {[...Array(4)].map((_, index) => (
          <Typography key={index} color="primary">
            ★
          </Typography>
        ))}
        <Typography color="disabled">★</Typography>
      </Box>

      {/* Hotel Name */}
      <Typography component="div" variant="h6">
        GRT HOTELS
      </Typography>

      {/* Confirmation & Favorite */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'flex-start' }, // Center content on small screens
          alignItems: 'center',
        }}
      >
        <CheckCircle color="success" sx={{ marginRight: '4px' }} />
        <Typography variant="body2" color="textSecondary">
          Confirmed
        </Typography>
        <IconButton color="default" size="small">
          <FavoriteBorder />
        </IconButton>
      </Box>

      {/* Location */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'flex-start' }, // Center content on small screens
          alignItems: 'center',
          marginTop: '8px',
        }}
      >
        
        <Typography variant="body2" color="textSecondary">
        <LocationOn sx={{fontSize:"15px"}}  color="error" />  GRT HOTEL, Palanganatham Madurai
        </Typography>
      </Box>

      {/* Phone */}
      <Button
        variant="outlined"
        startIcon={<Phone />}
        sx={{
          marginTop: '8px',
          borderColor: 'black',
          color: 'black',
          display: 'block', // Full width button
          marginLeft: { xs: 'auto', sm: 0 }, // Center button on small screens
          marginRight: { xs: 'auto', sm: 0 }, // Center button on small screens
        }}
      >
        9075642376
      </Button>
    </CardContent>
  </Box>
</Card>
    </>
  ):(
  <>
  <Card
      sx={{
        display: 'flex',
        backgroundColor: '#ffffe0',
        borderRadius: '15px',
        padding: '16px',
        maxWidth: 600, // Ensures cards don't take up too much space
      }}
    >
      {/* Image */}
      <CardMedia
        component="img"
        sx={{ width: 150, borderRadius: '10px' }}
        image="your-image-url-here"
        alt="Hotel"
      />

      {/* Content */}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
        <CardContent sx={{ flex: '1 0 auto', paddingBottom: '8px' }}>
          {/* Star Rating */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {[...Array(4)].map((_, index) => (
              <Typography key={index} color="primary">
                ★
              </Typography>
            ))}
            <Typography color="disabled">★</Typography>
          </Box>

          {/* Hotel Name */}
          <Typography component="div" variant="h6">
            GRT HOTELS
          </Typography>

          {/* Confirmation & Favorite */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle color="success" sx={{ marginRight: '4px' }} />
            <Typography variant="body2" color="textSecondary">
              Confirmed
            </Typography>
            <IconButton color="default" size="small">
              <FavoriteBorder />
            </IconButton>
          </Box>

          {/* Location */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <LocationOn color="error" />
            <Typography variant="body2" color="textSecondary">
              GRT HOTEL, Palanganatham Madurai
            </Typography>
          </Box>

          {/* Phone */}
          <Button
            variant="outlined"
            startIcon={<Phone />}
            sx={{
              marginTop: '8px',
              borderColor: 'black',
              color: 'black',
            }}
          >
            9075642376
          </Button>
        </CardContent>
      </Box>
    </Card>
  </>)}
    </>
  );
};

function TodayOffer() {

  const navigate = useNavigate();

  const handleDetails=()=>{
    navigate('/categories-details');
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  return (
   <>
   {isMobile ? (
   <>
   <NavBar/>
    <Container>
      <Container>
      <Grid  mt={isMobile ? 2 : 3} container spacing={2}  justifyContent="center">
          <Grid onClick={handleDetails} item xs={12} sm={6} md={6}>
            <HotelCard />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <HotelCard />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container direction="row" justifyContent="center" alignItems="center" mt={isMobile ? 2 : 3}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <CCarousel controls transition="crossfade" style={{ maxWidth: '100%' }}>
                <CCarouselItem>
                  <CImage
                    className="d-block w-100"
                    src={Sales}
                    alt="slide 1"
                    style={{  height: isMobile ? '100%' : '100%', width: '100%', objectFit: 'cover'  }}
                  />
                </CCarouselItem>
                <CCarouselItem>
                  <CImage
                    className="d-block w-100"
                    src={Sales}
                    alt="slide 2"
                    style={{  height: isMobile ? '100%' : '100%', width: '100%', objectFit: 'cover'  }}
                  />
                </CCarouselItem>
                <CCarouselItem>
                  <CImage
                    className="d-block w-100"
                    src={Sales}
                    alt="slide 3"
                    style={{  height: isMobile ? '100%' : '100%', width: '100%', objectFit: 'cover'  }}
                  />
                </CCarouselItem>
              </CCarousel>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid  mt={isMobile ? 2 : 3} container spacing={2}  justifyContent="center">
          <Grid onClick={handleDetails} item xs={12} sm={6} md={6}>
            <HotelCard />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <HotelCard />
          </Grid>
        </Grid>
      </Container>
    </Container>
    <Footer/>
   </>
  ):(
  <>
   <NavBar/>
    <Container>
      <Grid mt={5} container spacing={2} justifyContent="center">
        <Grid onClick={handleDetails} item xs={12} sm={6} md={6}>
          <HotelCard  />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <HotelCard />
        </Grid>
      </Grid>
      <Grid mt={3} mb={3} container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={6}>
          <HotelCard />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <HotelCard />
        </Grid>
      </Grid>
    </Container>
    <Container>
    <Grid container direction="row" justifyContent="center" alignItems="center" mt={3} >
              <Grid item xs={12} >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <CCarousel  controls transition="crossfade" style={{ height: '140px',width:"100%" }}>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Sales} alt="slide 1" style={{ height: '140px',width:"100%", objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Sales} alt="slide 2" style={{ height: '140px',width:"100%", objectFit: 'cover' }} />
                          </CCarouselItem>
                          <CCarouselItem>
                            <CImage className="d-block w-100" src={Sales} alt="slide 3" style={{ height: '140px',width:"100%", objectFit: 'cover' }} />
                          </CCarouselItem>
                        </CCarousel>
                      </Box>
              </Grid>
            </Grid>
    </Container>
    <Container>
      <Grid mt={3} container spacing={2} justifyContent="center">
        <Grid onClick={handleDetails} item xs={12} sm={6} md={6}>
          <HotelCard  />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <HotelCard />
        </Grid>
      </Grid>
      <Grid mt={3} mb={3} container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={6}>
          <HotelCard />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <HotelCard />
        </Grid>
      </Grid>
    </Container>
    <Foot/>
  </>
)}
   </>
  );
}

export default TodayOffer;
