import React, { useState,useEffect } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CCollapse, CRow } from '@coreui/react'
// import { DocsExample } from 'src/components'
// import Typography from '../../theme/typography/Typography'
import {Typography,Button,Grid,TextField,Tooltip,Card,CardMedia,CardContent,IconButton} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ITEM_HEIGHT = 48;

const Collapses = () => {
  const [store, setStore] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedServiceDetails, setSelectedServiceDetails] = useState(null);
  const [deletedata, setDelete] = useState('');
  const [deleteopen, setDeleteOPen] = useState(false);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    try {
      const response = await fetch("/api/servicelists", { method: "GET", redirect: "follow" });
      if (response.ok) {
        const result = await response.json();
        setStore(result.data); // Adjust based on your API response
      } else {
        console.error('Unexpected Error', response.status);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const getServiceById = async (serviceId) => {
    try {
      const response = await fetch(`/api/servicedata?id=${serviceId}`, { method: "GET", redirect: "follow" });
      if (response.ok) {
        const result = await response.json();
        setSelectedServiceDetails(result.data); // Adjust based on your API response
      } else {
        console.error('Unexpected Error', response.status);
      }
    } catch (error) {
      console.error('Error fetching service details:', error);
    }
  };

  const userDelete = async (serviceId) => {
    try {
      const requestOptions = {
        method: "DELETE",
        redirect: "follow",
      };
      
      const response = await fetch(`/api/delete_service?id=${serviceId}`, requestOptions);
      
      if (response.ok) {
        const result = await response.json();
        setDelete(result);
        console.log("Deleted data:", result);
        getServices(); // Refresh the services after deletion
      } else {
        console.error("Error deleting service", response.status);
      }
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };
  
  
  

  const handleMenuOpen = (event, serviceId) => {
    setAnchorEl(event.currentTarget);
    setSelectedServiceId(serviceId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = async () => {
    if (selectedServiceId) {
      await getServiceById(selectedServiceId);
      setDetailsOpen(true);
    }
  };

  const handleClose = () => {
    setDetailsOpen(false);
    setAnchorEl(null);
  };

  const handleDeleteOpen = async () => {
    if (selectedServiceId) {
      await getServiceById(selectedServiceId);
      setDeleteOPen(true);
    }
  };

  const handleDeleteClose = () => {
    setDeleteOPen(false);
  };

  const handleDelete = () => {
    if (selectedServiceId) {
      userDelete(selectedServiceId); // Delete by selectedServiceId
      setDeleteOPen(false);
      window.location.reload()
    }
  };

  const menuopen = Boolean(anchorEl);

  return (
    <>
      <Grid container spacing={2}>
        {Array.isArray(store) && store.length > 0 ? (
          store.map((item) => (
            <Grid item xs={12} md={6} key={item._id}>
              <Card sx={{ padding: 1, position: 'relative' }}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item xs={5}>
                    <CardMedia
                      component="img"
                      sx={{ width: "100%", height: "200%" }}
                      image={item.addimages[0]} // Your image source here from item
                      alt={item.servicename} // Your alt text
                    />
                  </Grid>
                  <Grid item xs={6} container direction="column" justifyContent="space-evenly" alignItems="flex-start">
                    <Typography sx={{ fontSize: "15px" }}><b>Store Name:</b> {item.servicename}</Typography>
                    <Typography sx={{ fontSize: "15px", marginTop: "8px" }}><b>Phone Number:</b> {item.number}</Typography>
                    <Typography sx={{ fontSize: "15px", marginTop: "8px" }}><b>Category:</b> {item.servicetype.join(', ')}</Typography>
                    <Typography sx={{ fontSize: "15px", marginTop: "8px" }}><b>Address:</b> {item.addressline1}, {item.addressline2}, {item.city}, {item.pincode}</Typography>
                  </Grid>
                </Grid>
                <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={(event) => handleMenuOpen(event, item._id)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{ 'aria-labelledby': 'long-button' }}
                  anchorEl={anchorEl}
                  open={menuopen}
                  onClose={handleMenuClose}
                  slotProps={{ paper: { style: { maxHeight: ITEM_HEIGHT * 4.5, width: '20ch' } } }}
                >
                  <MenuItem onClick={handleClickOpen}>
                    <LibraryBooksIcon sx={{ fontSize: "15px", color: "green", marginRight: "5px" }} /> Show details
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteOpen(item._id)}>
                    <DeleteIcon sx={{ fontSize: "15px", color: "red", marginRight: "5px" }} /> Delete
                  </MenuItem>
                </Menu>
                <Dialog
                  open={detailsOpen}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">{"Store Details"}</DialogTitle>
                  <DialogContent sx={{ width: "500px" }}>
                    {selectedServiceDetails ? (
                      <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                        <Grid item xs={8} container direction="column" justifyContent="center" alignItems="flex-start">
                          <Typography sx={{ fontSize: "15px" }} align="center">Name: {selectedServiceDetails.servicename}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">Phone: {selectedServiceDetails.number}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">Websitelink: {selectedServiceDetails.websitelink}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">Address:{selectedServiceDetails.addressline1}, {selectedServiceDetails.addressline2}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">Area:{selectedServiceDetails.area}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">City:{selectedServiceDetails.city},</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">Pincode:{selectedServiceDetails.pincode}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="center">Categories:{selectedServiceDetails.servicetype.join(', ')}</Typography>
                          <CardMedia
                      component="img"
                      sx={{ width: "100%", height: "100%" }}
                      image={selectedServiceDetails.addimages[0]} // Your image source here from item
                      alt={item.servicename} // Your alt text
                    />
                          {/* <Typography sx={{ fontSize: "15px" }} align="center">Gallery:{selectedServiceDetails.addimages}</Typography> */}
                        </Grid>
                        {/* <Grid item xs={6} container direction="column" justifyContent="center" alignItems="flex-start">
                          <Typography sx={{ fontSize: "15px" }} align="left"></Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left"></Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left"></Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left"></Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left"> </Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left">{selectedServiceDetails.pincode}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left"></Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left">{selectedServiceDetails.pannumber}</Typography>
                          <Typography sx={{ fontSize: "15px" }} align="left">{selectedServiceDetails.membership}</Typography>
                        </Grid> */}
                      </Grid>
                    ) : (
                      <Typography variant="body2">Loading...</Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
                <Dialog
        open={deleteopen}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Are you sure, You want to delete this User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between"alignItems="center" >
          <Button  onClick={handleDeleteClose}>
           No
          </Button>
          <Button onClick={handleDelete} s>
            Yes
          </Button>
          </Grid>
        </DialogActions>
      </Dialog>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">No Stores available</Typography>
        )}
      </Grid>
    </>
  );
};

export default Collapses;