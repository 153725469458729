import { Container, Typography, Box, Grid,Button} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Order from '../../assets/img/packers.jpg'
import './style.css'
import NavBar from '../navbar';
import Footer from '../footer';
// import { Opacity } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
// import { deepOrange, deepPurple } from '@mui/material/colors';




const OrderCard = (props) => {
 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return (
       
     <>
     {isMobile ? (
     <>
      <Card style={{ display: 'flex', marginBottom: '10px',backgroundColor:"#f7f4cd" }}>
        <CardMedia
          component="img"
          style={{ width: 100 }}
          src={Order} // Replace with your image path
          alt="Order"
        />
        <CardContent style={{ flex: '1 0 auto' }}>
          <Typography sx={{fontSize:"13px"}} variant="body1" component="div">
            {props.my_title}
          </Typography>
          <Typography sx={{fontSize:"10px"}} variant="body2" color="text.secondary">
            {props.my_description}
          </Typography>
          <Typography sx={{fontSize:"8px"}} variant="body2" color="text.secondary">
          {props.my_day}
          </Typography>
        </CardContent>
        <div style={{ alignSelf: 'flex-start', marginTop: '15px',marginRight:"8px",  fontSize:"8px", color: 'black' }}>
        {props.my_time}
        </div>
      </Card>
     </>
     ):(
     <>
      <Card style={{ display: 'flex', marginBottom: '10px',backgroundColor:"#f7f4cd" }}>
        <CardMedia
          component="img"
          style={{ width: 250 }}
          src={Order} // Replace with your image path
          alt="Order"
        />
        <CardContent >
          <Typography sx={{fontSize:"25px",fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", color: "#000"}} variant="body1" component="div">
            {props.my_title}
          </Typography>
          <Typography className='mt-1' sx={{fontSize:"15px",fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} variant="body2" color="text.secondary">
            {props.my_description}
          </Typography>
          <Typography className='mt-2' sx={{fontSize:"10px",fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} variant="body2" color="text.secondary">
          {props.my_day}
          </Typography>
        </CardContent>
        <div style={{ alignSelf: 'flex-start', marginTop: '15px',marginRight:"8px",  fontSize:"8px", color: 'black' }}>
        {props.my_time}
        </div>
      </Card>
     </>
    )}
     </>
      
    );
  };
  

function Notification() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const ListItem = ({ delay, children }) => (
      <li style={{ '--delay': `${delay}s` }}>
          <a href="/notifications">{children}</a>
      </li>
  );


    return (
        <>

            {isMobile ? (
                <>
                    <NavBar />

                    <Container>
                        <Grid item xs={12} mt={3} container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                <Typography id="header-mob">Notification List</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} sx={{ mt: 0 }} >
                            <hr style={{ margin: '0', padding: '0', border: 'none', borderBottom: '1px solid #000' }} />
                        </Grid>
                        <Grid item xs={12} mt={1} container direction="row" justifyContent="space-between" alignItems="center" >
                            <Grid item xs={4}  container direction="row" justifyContent="flex-start" alignItems="center">
                            <div class="drop-mob">
  <button class="drop-mob-btn" aria-haspopup="menu">
    <span>Time line<ArrowDropDownIcon/></span>
  </button>
  <ul className="drop-mob-content" role="menu">
        <ListItem delay={1}>Today</ListItem>
        <ListItem delay={2}>Yesterday</ListItem>
        <ListItem delay={3}>1 Week</ListItem>
        <ListItem delay={4}>2 Week</ListItem>
    </ul>
</div>
                            </Grid>
                            <Grid container className='mb-1 mt-1' item xs={5}  direction="row" justifyContent="space-around" alignItems="center">
                                <Grid>
                                <Button id="head-button-mob">read</Button>
                                </Grid>
                                <Grid>
                                <Button id="head-button-mob">More</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                    <Grid mt={3} container spacing={2} >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Today
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Box className="container-mob">
        <OrderCard 
        my_title="Order Updated"
        my_description="Your order #12345"
        my_day="23 mins ago" />
        <OrderCard 
        my_title="Order Delivered"
        my_description="Your order #12345"
        my_day="30 mins ago"  />
        </Box>
      </Grid>
    </Grid>

    <Grid container spacing={2} >
      <Grid mt={3} item xs={12}>
        <Typography variant="h6" gutterBottom>
         Yesterday
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Box className="container-mob">
        <OrderCard 
        my_title=" Update" 
        my_description="Your order #12345"
        my_day="1 day ago"/>
        <OrderCard 
        my_title=" Updated soon"
        my_description="Your order #12345"
        my_day="1 day ago"  />
        </Box>
      </Grid>
    </Grid>
                    </Container>

                    <Footer />

                </>
            ) : (
                <>
                <NavBar/>
                <Container>
                        <Grid item xs={12} mt={5} container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                <Typography id="header">Notifications</Typography>
                            </Grid>
                            <Grid item xs={12} mb={1}  container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid xs={1.5} container direction="row" justifyContent="space-evenly" alignItems="center">
                            <Typography id="All">All</Typography>
                            <Stack direction="row" spacing={2}>
      <Avatar sx={{ Opacity:0.1,width:"80px",height:"80px" }}>20</Avatar>
    </Stack>
                            </Grid>
                            <Grid container className='mb-1 mt-1' item xs={2.5}  direction="row" justifyContent="space-evenly" alignItems="center">
                                <Grid>
                                <Button id="head-button-mob">read</Button>
                                </Grid>
                                <Grid>
                                <Button id="head-button-mob">More</Button>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} sx={{ mt: 0 }} >
                            <hr style={{ margin: '0', padding: '0', border: 'none', borderBottom: '1px solid #000' }} />
                        </Grid>
                        <Grid item xs={12} mt={1} container direction="row" justifyContent="space-between" alignItems="center" >
                            <Grid item xs={4}  container direction="row" justifyContent="flex-start" alignItems="center">
                            <div class="drop">
  <button class="drop-btn" aria-haspopup="menu">
    <span>Time line<ArrowDropDownIcon/></span>
  </button>
  <ul className="drop-content" role="menu">
        <ListItem delay={1}>Today</ListItem>
        <ListItem delay={2}>Yesterday</ListItem>
        <ListItem delay={3}>1 Week</ListItem>
        <ListItem delay={4}>2 Week</ListItem>
    </ul>
</div>
                            </Grid>
                           
                        </Grid>
                    </Container>
                    <Container>
                    <Grid mt={3} container spacing={2} >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Today
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Box className="container-mob">
        <OrderCard 
        my_title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        my_description="20% off on Purchases"
        my_day="23 mins ago" />
        <OrderCard 
        my_title="Order Delivered"
        my_description="Your order #12345"
        my_day="30 mins ago"  />
        <OrderCard 
        my_title="Order Delivered"
        my_description="Your order #12345"
        my_day="30 mins ago"  />
        </Box>
      </Grid>
    </Grid>

    <Grid container spacing={2} >
      <Grid mt={3} item xs={12}>
        <Typography variant="h6" gutterBottom>
         Yesterday
        </Typography>
      </Grid>
      <Grid item xs={12} mb={3}>
      <Box className="container-mob">
        <OrderCard 
        my_title=" Update" 
        my_description="Your order #12345"
        my_day="1 day ago"/>
        <OrderCard 
        my_title=" Updated soon"
        my_description="Your order #12345"
        my_day="1 day ago"  />
        </Box>
      </Grid>
    </Grid>
                    </Container>
                    <Footer/>
                </>
            )}

        </>
    )
}

export default Notification
