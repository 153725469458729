import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {Table,Typography,Grid,Card,CardMedia,Alert,Snackbar,TextField }from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Freelancer = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deletedata, setDelete] = useState('');
  const [deleteopen, setDeleteOPen] = useState(false);
  const [user, setUser] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [aadharnumber, setAadharnumber] = useState('');
  const [pannumber, setPannumber] = useState('');
  const [membership, setMembership] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [editData, setEditData] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [color, setColor] = useState('');
  const [msg, setMsg] = useState('');

  // Fetch user data
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    fetch('/api/userdetails', { method: 'GET', redirect: 'follow' })
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        setData(result.data.data);
      })
      .catch((error) => console.log('error', error));
  };

  // Fetch user data by ID
  const getDataById = (userId) => {
    fetch(`/api/useradmin?id=${userId}`, { method: 'GET', redirect: 'follow' })
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        setUser(result.data.data);
        setName(result.data.data.username || '');
        setEmail(result.data.data.email || '');
        setPhonenumber(result.data.data.phonenumber || '');
        setAadharnumber(result.data.data.aadharnumber || '');
        setPannumber(result.data.data.pannumber || '');
        setMembership(result.data.data.membership || '');
        setAddressLine1(result.data.data.addressline1 || '');
        setAddressLine2(result.data.data.addressline2 || '');
        setCity(result.data.data.city || '');
        setPincode(result.data.data.pincode || '');
        setState(result.data.data.state || '');
      })
      .catch((error) => console.log('error', error));
  };

  // Edit user details
  const EditUser = async (userId) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      username: name || user.username,
      email: email || user.email,
      phonenumber: phonenumber || user.phonenumber,
      city: city || user.city,
      state: state || user.state,
      pincode: pincode || user.pincode,
      addressline1: addressLine1 || user.addressline1,
      addressline2: addressLine2 || user.addressline2,
      aadharnumber: aadharnumber || user.aadharnumber,
      pannumber: pannumber || user.pannumber,
      membership: membership || user.membership,
    });

    try {
      const response = await fetch(`/api/user_update?id=${userId}`, {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      });
      const result = await response.json();

      if (response.status === 200 || response.status === 400) {
        setEditData([result.data]);
        setMsgOpen(true);
        setColor('success');
        setMsg('User updated successfully');
      } else {
        setMsgOpen(true);
        setColor('error');
        setMsg('Unexpected Error');
      }
    } catch (error) {
      setMsgOpen(true);
      setColor('error');
      setMsg('Error updating user');
    }
  };

  const userDelete=(userId)=>{
    try{
      const requestOptions = {
        method: "DELETE",
        redirect: "follow"
      };
      
      fetch(`/api/userdelete?id=${userId}`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        setDelete(result,"deleted data");
      })
      .catch((error) => console.log('error', error));
    }catch(error){

    }
  }

  // Open and close dialogs
  const handleClickOpen = (userId) => {
    setOpen(true);
    getDataById(userId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = (userId) => {
    setEdit(true);
    getDataById(userId);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handleDeleteOpen = (userId) => {
    setDeleteOPen(true);
    getDataById(userId);
  };

  const handleDeleteClose = () => {
    setDeleteOPen(false);
  };

  const handleSubmit = () => {
    EditUser(user._id);
    setEdit(false);
    window.location.reload()
  };

  const handleDelete = () => {
    userDelete(user._id);
    setDeleteOPen(false);
    window.location.reload()
  };

  const handleSnackClose = () => {
    setMsgOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
     
            <TableContainer  component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>User Name</StyledTableCell>
                    <StyledTableCell align="center">Phone Number</StyledTableCell>
                    <StyledTableCell align="center">Mail</StyledTableCell>
                    <StyledTableCell align="center">Earnings</StyledTableCell>
                    <StyledTableCell align="center">Membership</StyledTableCell>
                    <StyledTableCell align="center">Details</StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {data.length > 0 ? (
  data
    .filter((item) => item.role === 'freelancer')
    .map((item, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell component="th" scope="row">
          {item.username}
        </StyledTableCell>
        <StyledTableCell align="center">{item.phonenumber}</StyledTableCell>
        <StyledTableCell align="center">{item.email}</StyledTableCell>
        <StyledTableCell align="center">{item.earning}</StyledTableCell>
        <StyledTableCell align="center">{item.membership}</StyledTableCell>
        <StyledTableCell align="center">
          <InfoIcon onClick={() => handleClickOpen(item._id)} />
        </StyledTableCell>
        <StyledTableCell align="center">
          <IconButton onClick={handleMenuOpen}>
            <EditIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleEditOpen(item._id)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDeleteOpen(item._id)}>Delete</MenuItem>
          </Menu>
        </StyledTableCell>
      </StyledTableRow>
    ))
) : (
  <Typography>No data available</Typography>
)}
                </TableBody>
              </Table>

              {/* User Details Dialog */}
              <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"User Details"}</DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                  <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                      {/* Left Column */}
                      <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
                        <Typography sx={{ fontSize: '15px' }} align="center">Name:</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">Phone:</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">Email:</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">Address:</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">City:</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">State:</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">Pincode:</Typography>
                      </Grid>

                      {/* Right Column */}
                      <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
                        <Typography sx={{ fontSize: '15px' }} align="center">{user.username || ''}</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">{user.phonenumber || ''}</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">{user.email || ''}</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">
                          {user.addressline1 || ''} {user.addressline2 || ''}
                        </Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">{user.city || ''}</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">{user.state || ''}</Typography>
                        <Typography sx={{ fontSize: '15px' }} align="center">{user.pincode || ''}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Edit User Dialog */}
              <Dialog open={edit} onClose={handleEditClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Edit User"}</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        value={phonenumber}
                        onChange={(e) => setPhonenumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Aadhar Number"
                        variant="outlined"
                        value={aadharnumber}
                        onChange={(e) => setAadharnumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="PAN Number"
                        variant="outlined"
                        value={pannumber}
                        onChange={(e) => setPannumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Membership"
                        variant="outlined"
                        value={membership}
                        onChange={(e) => setMembership(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Address Line 1"
                        variant="outlined"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Address Line 2"
                        variant="outlined"
                        value={addressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="City"
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Pincode"
                        variant="outlined"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="State"
                        variant="outlined"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleEditClose}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
              </Dialog>
              <Dialog
        open={deleteopen}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Are you sure, You want to delete this User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between"alignItems="center" >
          <Button  onClick={handleDeleteClose}>
           No
          </Button>
          <Button onClick={handleDelete} s>
            Yes
          </Button>
          </Grid>
        </DialogActions>
      </Dialog>

              {/* Snackbar for messages */}
              <Snackbar open={msgOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={color}>
                  {msg}
                </Alert>
              </Snackbar>
            </TableContainer>
         
        
    </>
  );
};

export default Freelancer;