import { Typography } from "@mui/material";
import { Paper, Grid, FormControlLabel,Switch } from "@mui/material"
import { styled } from '@mui/material/styles';
// import NavBar from "../navbar";
// import Footer from "../footer";
// import deactivate from '../../assets/img/deactivate.png'
import Tooltip from '@mui/material/Tooltip';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width:"100%"
  }));
  

function Privacy(){

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    return(
        <>
        {/* <NavBar/> */}
        <Item >
        <Tooltip disableFocusListener title="Note: If you Toggle On the button, it will disable your account, It will not show to Your Clients">
        <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center"  >
            
<Grid item xs={6} container direction="row" justifyContent="flex-start" alignItems="center" >
<Typography sx={{fontSize:"20px",color:"black"}}>Disable Your account</Typography>
</Grid>
<Grid item xs={6} container direction="row" justifyContent="flex-end" alignItems="center" >
<FormControlLabel
control={<IOSSwitch sx={{ m: 1 }}  />}

/>
</Grid>
            
        </Grid>
        </Tooltip>
        </Item>

        {/* <Grid mt={4} item xs={12} container direction="row" justifyContent="space-evenly" alignItems="center">
  <img
    src={deactivate}
    alt="Deactivate"
    style={{
      width: "400px",
      height: "300px",
      borderRadius: "20px"
    }}
  />
</Grid> */}
        {/* <Footer/> */}
        </>
    )
}

export default Privacy