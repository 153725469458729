import { Container, Typography, Box, Grid, List, ListItem, ListItemIcon, ListItemText,Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './style.css'
import NavBar from '../navbar';
import Footer from '../footer';
import Thumbsup from '../../assets/img/thumbsup.webp'
import Work from '../../assets/qik call mobile view icons/order.png'
import Time from '../../assets/qik call mobile view icons/timetable.png'
import Salary from '../../assets/qik call mobile view icons/salary.png'
import Target from '../../assets/qik call mobile view icons/forbidden-sign.png'
import Pressure from '../../assets/qik call mobile view icons/Group 291.png'
import { Button } from 'react-bootstrap';
// import { BorderAll, BorderAllOutlined } from '@mui/icons-material';


function Freelancer() {

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleFreelancer =()=>{
    navigate('/registerfreelancer')
  }

  return (
    <>
      {isMobile ? (
        <>
          <NavBar />
          <Container maxWidth="1200px" className="container-custom">
            <section className="ezy__comingsoon8_w2RBJjPU mt-5">
              <svg className="position-absolute top-0 end-0" width="400" height="300" viewBox="0 0 1024 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H1024V539H539C241.319 539 0 297.681 0 0Z" fill="currentColor" />
              </svg>

              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-5 d-flex flex-column justify-content-center text-center text-lg-start">
                    <Typography variant='h3' className="ezy__comingsoon8_w2RBJjPU-heading mb-4">Reduce Your Time</Typography>
                    <Typography variant='h3' className="ezy__comingsoon8_w2RBJjPU-heading mb-4">Increase Your Money!</Typography>
                    <p className="ezy__comingsoon8_w2RBJjPU-sub-heading mb-5 mb-lg-0">
                      An activity that requires a person's mental or physical effort is work.If a person is trained for a
                      certain type of job, they may have a profession. Typically, a job would be a subset of someone's career.
                    </p>
                  </div>
                  <div className="col-12 col-lg-7">
                    <img src="https://cdn.easyfrontend.com/pictures/comingsoon/eight.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </Container>


          <Container maxWidth="1200px" sx={{ backgroundColor: "#D1EDEE" }}>
            <Grid container xs={12} direction="row" ml={1} mt={10} mb={3}>
              <Container>
                <Grid item xs={12} md={8} className='mt-3 mb-3'>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                          src={Work}
                          alt=""
                          width="90px"
                          height="90px"
                          id="image-border"
                        />
                      </Box>
                      <Typography sx={{   fontSize: "13px",   color: "#000",   fontFamily: "'Anton', sans-serif",   fontStyle: "italic",  textAlign: "center" }} className='mt-2'>
                        <b>Work From Home</b>
                      </Typography>
                      <Typography id="text-mob" variant="body1" className="mt-2">
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>

                    </Grid>
                    
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                          src={Time}
                          alt=""
                          width="90px"
                          height="90px"
                          id="image-border"
                        />
                      </Box>
                      <Typography sx={{   fontSize: "13px",   color: "#000",   fontFamily: "'Anton', sans-serif",   fontStyle: "italic",  textAlign: "center" }} className="mt-2">
                        <b>At Your Free Time</b>
                      </Typography>
                      <Typography id="text-mob" variant="body1" className="mt-2" >
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>

                    </Grid>
                    
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                         src={Salary}
                         alt=""
                         width="90px"
                         height="90px"
                         id="image-border"
                        />
                      </Box>
                      <Typography sx={{   fontSize: "13px",   color: "#000",   fontFamily: "'Anton', sans-serif",   fontStyle: "italic",  textAlign: "center" }} className="mt-3 mb-3" >
                        <b>Best Package</b>
                      </Typography>
                      <Typography id="text-mob" variant="body1" className="mt-2" >
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>

                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Container>
              <Grid container direction="row" justifyContent="center" alignItems="center" mb={3}>

                <Grid xs={12} className='mt-3 mb-3'>
                  <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                         src={Target}
                         alt=""
                         width="90px"
                         height="90px"
                         id="image-border"
                        />
                      </Box>
                      <Typography sx={{   fontSize: "13px",   color: "#000",   fontFamily: "'Anton', sans-serif",   fontStyle: "italic",  textAlign: "center" }} className="mt-3" >
                        <b>No Target</b>
                      </Typography>
                      <Typography id="text-mob" variant="body1" className="mt-3" >
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>

                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                         src={Pressure}
                         alt=""
                         width="90px"
                         height="90px"
                         id="image-border"
                        />
                      </Box>
                      <Typography sx={{   fontSize: "13px",   color: "#000",   fontFamily: "'Anton', sans-serif",   fontStyle: "italic",  textAlign: "center" }} className="mt-3"
                      >
                        <b>No Pressure</b>
                      </Typography>
                      <Typography id="text-mob" variant="body1" className="mt-2"
                      >
                        If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location
                      </Typography>

                    </Grid>


                  </Grid>
                </Grid>

              </Grid>
            </Container>


          </Container>
          <Container>
            <Grid xs={12} mt={2} mb={3} container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={4} container direction="row" justifyContent="flex-start" alignItems="center">
                <img src={Thumbsup} alt="Benefits of work" width={"120px"} height={"250px"} />
              </Grid>
              <Grid item xs={7} container direction="row" justifyContent="space-evenly" alignItems="center">
                <Typography id="work-head-mob" ><b>Benifits of Work</b></Typography>

                <List >
                  <ListItem disablePadding>
                    <FiberManualRecordIcon id="work-icon-mob" />
                    <Typography id="work-text-mob" >Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus.</Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <FiberManualRecordIcon id="work-icon-mob" />
                    <Typography id="work-text-mob" >Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus.</Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <FiberManualRecordIcon id="work-icon-mob" />
                    <Typography id="work-text-mob" >Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus.</Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <FiberManualRecordIcon id="work-icon-mob" />
                    <Typography id="work-text-mob" >Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus.</Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <FiberManualRecordIcon id="work-icon-mob" />
                    <Typography id="work-text-mob" >Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus.</Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </>) : (
        <>
          <NavBar />
          <Container maxWidth="lg" sx={{ backgroundColor: "#fff",borderRadius:"18px"}} >
            <section class="ezy__comingsoon8_w2RBJjPU mt-5 mb-5">
              <svg class="position-absolute top-0 end-0" width="1024" height="300" viewBox="0 0 1024 300" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H1024V539H539C241.319 539 0 297.681 0 0Z" fill="currentColor" />
              </svg>

              <div class="container">
                <div class="row justify-content-between">
                  <div class="col-10 col-lg-4 d-flex flex-column justify-content-center text-center text-lg-start">
                    <Typography variant='h3' class="ezy__comingsoon8_w2RBJjPU-heading mb-4" sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }}>Reduce Your Time</Typography>
                    <Typography variant='h3' class="ezy__comingsoon8_w2RBJjPU-heading mb-4" sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }}>Increase Your Money!</Typography>
                    <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} class="mt-1 mb-5 mb-lg-0" >
                      An activity that requires a person's mental or physical effort is work.If a person is trained for a
                      certain type of job, they may have a profession. Typically, a job would be a subset of someone's career.
                    </Typography>
                  </div>
                  <div class="col-10 col-lg-6">
                    <img src="https://cdn.easyfrontend.com/pictures/comingsoon/eight.png" alt="" class="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </Container>

          <Container  sx={{ backgroundColor: "#D1EDEE",borderRadius:"18px",padding: 2 }}>
            <Grid container xs={12} direction="row" ml={1} mt={5} mb={3}>
              <Container className="container">
                <Grid xs={12} className="mt-3 mb-3">
                  <Grid mt={3} container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                          src={Work}
                          alt=""
                          width="150px"
                          height="150px"
                          id="image-border"
                        />
                        <Typography sx={{ color: "#000", fontSize: "20px", fontFamily: "Flama Bold Italic" }} className="mt-3">
                          <b>Work From Home</b>
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize: "12px", fontFamily: "Flama Bold Italic" }} variant="body1" className="mt-3" align="center">
                          If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                         src={Time}
                         alt=""
                         width="150px"
                         height="150px"
                         id="image-border"
                        />
                        <Typography sx={{ color: "#000", fontSize: "20px", fontFamily: "Flama Bold Italic" }} className="mt-3">
                          <b>At Your Free Time</b>
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize: "12px", fontFamily: "Flama Bold Italic" }} variant="body1" className="mt-3" align="center">
                          If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                          src={Salary}
                          alt=""
                          width="150px"
                          height="150px"
                          id="image-border"
                        />
                        <Typography sx={{ color: "#000", fontSize: "20px", fontFamily: "Flama Bold Italic" }} className="mt-3">
                          <b>Best Package</b>
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize: "12px", fontFamily: "Flama Bold Italic" }} variant="body1" className="mt-3" align="center">
                          If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid container xs={12} direction="row" mb={3}>
              <Container className="container">
                <Grid xs={12} className="mt-3 mb-3">
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                          src={Target}
                          alt=""
                          width="150px"
                          height="150px"
                          id="image-border"
                        />
                        <Typography sx={{ color: "#000", fontSize: "20px", fontFamily: "Flama Bold Italic" }} className="mt-3">
                          <b>No Target</b>
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize: "12px", fontFamily: "Flama Bold Italic" }} variant="body1" className="mt-3" align="center">
                          If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid item xs={3}>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img
                          src={Pressure}
                          alt=""
                          width="150px"
                          height="150px"
                          id="image-border"
                        />
                        <Typography sx={{ color: "#000", fontSize: "20px", fontFamily: "Flama Bold Italic" }} className="mt-3">
                          <b>No Pressure</b>
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize: "12px", fontFamily: "Flama Bold Italic" }} variant="body1" className="mt-3" align="center">
                          If you are relocating to another place or if you even just want to send some belongings somewhere, find the best deals on the most reliable packers and movers for your location.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Container>
          <Container>
            <Grid xs={12} mt={5} container direction="row" justifyContent="space-evenly" alignItems="center">
              <Grid item xs={6} container direction="row" justifyContent="center" alignItems="center">
                <img src={Thumbsup} alt="Benefits of work" width={"450px"} height={"350px"} />
              </Grid>
              <Grid item xs={5} container direction="row" justifyContent="center" alignItems="center">
                <Typography sx={{ fontSize: "40px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }}><b>Benifits of Work</b></Typography>
                <List>
                  <ListItem >
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ color: "#2d2859", }} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} primary="Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus." />
                  </ListItem>
                  <ListItem >
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ color: "#2d2859", }} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} primary="Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus." />
                  </ListItem>
                  <ListItem >
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ color: "#2d2859" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} primary="Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus." />
                  </ListItem>
                  <ListItem >
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ color: "#2d2859" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} primary="Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus." />
                  </ListItem>
                  <ListItem className="bullet-item">
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ color: "#2d2859" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} primary="Lorem ipsum dolor sit amet consectetur. Netus eu libero at lectus netus accumsan id non phasellus." />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
          <Container>
          <Grid mt={5} mb={5} container direction="row" justifyContent="center" alignItems="center" >
          <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
  <Paper sx={{ width: "600px", height: "100px", padding: "16px",backgroundColor: "#2d2859",border:"1px solid #2d2859" }} elevation={3}>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <Typography color={"#fff"} variant="h6" align="center">
        If You Want to Join as Freelancer
      </Typography>
      <Button  onClick={handleFreelancer} variant="contained" fullWidth style={{ marginTop: 16, backgroundColor: "#ffffe0", color:"#000" }}>
  Register Here!
</Button>

    </Box>
  </Paper>
</Grid>
</Grid>

          </Container>
          <Footer />
        </>)}

    </>
  )
}

export default Freelancer