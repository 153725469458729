import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

// User side imports
import Freelancer from './layout/freelancer/index';
import HomePage from './layout/homepage/index';
import Business from './layout/Buisness';
import FreeListing from './layout/Freelisting';
import Contact from './layout/contactus';
import About from './layout/aboutus';
import Terms from './layout/termsandcondition';
import Privacy from './layout/privacy';
import CategoriesList from './layout/category';
import Wishlist from './layout/wishlist';
import Userprofile from './layout/userprofile';
import Notification from './layout/notification';
import ManageProfile from './layout/userprofile/profile';
import Advertise from './layout/advertise';
import Pricing from './layout/pricing';
import ServiceProfileForm from './layout/userprofile/service';
import CategoriesDetails from './layout/category/details';
import RegisterCustomer from './layout/register/customer';
import RegisterBusiness from './layout/register/business';
import RegisterFreelancer from './layout/register/freelancer';
import RegisterUser from './layout/register/user';
import TodayOffer from './layout/todayoffer';
import Signin from './layout/signin';
import BusinessDetails from './layout/Buisness/details';
import PrivacySetting from './layout/userprofile/privacy';
import FreelancerDetails from './layout/Buisness/freelancer';
import Userdetails from './layout/userprofile/param';

// Admin side imports
import Login from './views/pages/login/Login';
import Dashboard from './views/dashboard/Dashboard';
// import AppLayout from './applayout'
import DefaultLayout from './adminlayout/DefaultLayout';
import Customer from './views/base/customer/Customer';
import FreelacerAdmin from './views/base/freelancer/Freelancer';
import BusinessmanAdmin from './views/base/businessman/Businessman';
import CategoryAdmin from './views/base/category/Category';
import ServiceAdmin from './views/base/serivces/Services';
import AdvertisementAdmin from './views/base/advertisement/Advertisement';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          {/* User side routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/freelancer" element={<Freelancer />} />
          <Route path="/freelancerdetails" element={<FreelancerDetails />} />
          <Route path="/business" element={<Business />} />
          <Route path="/businessdetails" element={<BusinessDetails />} />
          <Route path="/free-listing" element={<FreeListing />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/termsandcondition" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/categorieslist/:id" element={<CategoriesList />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/userprofile" element={<Userprofile />} />
          <Route path="/usersdetails" element={<Userdetails />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/manageprofile" element={<ManageProfile />} />
          <Route path="/advertise" element={<Advertise />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/service" element={<ServiceProfileForm />} />
          <Route path="/categories-details/:id" element={<CategoriesDetails />} />
          <Route path="/today-offer" element={<TodayOffer />} />
          <Route path="/registercustomer" element={<RegisterCustomer />} />
          <Route path="/registerbusiness" element={<RegisterBusiness />} />
          <Route path="/registerfreelancer" element={<RegisterFreelancer />} />
          <Route path="/registeruser" element={<RegisterUser />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/privacysetting" element={<PrivacySetting />} />

          {/* Admin routes */}
          <Route path="/adminlogin" element={<Login />} />
          {/* <Route path="/main" element={<AppLayout />}></Route> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin/*" element={<DefaultLayout />} />
          <Route path="/admin/customer" element={<Customer />} />
          <Route path="/admin/freelancer" element={<FreelacerAdmin />} />
          <Route path="/admin/businessman" element={<BusinessmanAdmin />} />
          <Route path="/admin/category" element={<CategoryAdmin />} />
          <Route path="/admin/services" element={<ServiceAdmin />} />
          <Route path="/admin/advertise" element={<AdvertisementAdmin />} />

          {/* Fallback route for unmatched paths */}
          <Route path="*" element={<div>404 - Page not found</div>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
