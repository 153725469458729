import { Container, Typography, Box, Grid, IconButton, Button, Divider, Link, TextField,Snackbar,Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Cookies from 'js-cookie';
import Sign from '../../assets/img/image5.jpeg'













function Signin() {

    // const [value, setValue] = useState('');
    const [open, setOpen] = React.useState(false);
    const[clickOpen,setClickOpen] = React.useState(false);
    const [registeropen, setRegisteropen] = React.useState(false);
    // const [validated, setValidated] = useState(false);
    const [number,setNumber] = useState('')
    const [phone,setPhone] = useState('')
    const [password,setPassword] = useState('')
    const [color,setColor] = useState('')
    const [msg,setMsg] = useState('')
    const navigate = useNavigate();
    

    
 
  
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const handlesignin = () =>{
      try{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        const raw = JSON.stringify({
          "phonenumber": number,
          "password": password
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch("/api/login", requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.status === 400) {
              return { status_code: response.status, data: await response.json() };
          } else {
              return { status_code: response.status, data: { msg: 'Unexpected Error' } };
          }
      })
      .then((result) => {
          if (result.status_code === 200) {
              console.log(result.data.token, " Sign in Token stored");
              const token = result.data.token;
              Cookies.set('token', token, { expires: 7, secure: true });
  
              setOpen(true);
              setColor('success');
              setMsg(result.data.msg || "Register Success");
              setTimeout(() => {
                navigate('/');
              }, "1000");
  
          } else if (result.status_code === 400) {
              setOpen(true);
              setColor('error');
              setMsg(result.data.msg);
  
          } else if (result.status_code === 401) {
              // Handle unauthorized access
              setOpen(true);
              setColor('error');
              setMsg("Unauthorized access");
          }
      })
        .catch(error => console.log('error', error));
        
          }catch(error){
            console.error(error)
            setOpen(true);
            setColor('error');
            setMsg(error.response.data.message||'An Error Occured');
          }
    }
    
  
  
    const handleRegisterOpen = () => {
      setRegisteropen(true);
    };
  
    const handleRegisterClose = () => {
      setRegisteropen(false);
    };
  
    const handleClickOpen = () => {
      setClickOpen(true);
    };
  
    
  
    const handleClose = () => {
      setClickOpen(false);
    };
  
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffe0',
      ...theme.typography.body2,
      border:"1px solid #2d2859  ",
      padding: theme.spacing(1),
      textAlign: 'center',
      color: "#000",
      width:"100%",
     
    }));
  
    const navigateuser = () => {
      navigate("/registeruser");
    };
  
    const navigatebusiness = () => {
      navigate("/registerbusiness");
    };
  
    const navigatefreelancer = () => {
      navigate("/registerfreelancer");
    };

    
const BackHome =()=>{
  navigate("/")
}
  
  
  
    return (
      <>
        {isMobile ? (
          <>
            <Grid>
              <Grid
                sx={{ backgroundColor: "#f7f4cd", color: "#000", width: "600px", height: "500px" }}
              >
                <Typography sx={{ fontSize: "45px", fontWeight: "bold" }} >Sign in</Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>log into your account</Typography><br />
                <Typography className='mt-3' sx={{ fontSize: "15px", }}>To,Login please enter your mobile number</Typography>
                <TextField
                  placeholder='Enter your number'
                  className='mt-4'
                  value={number}
                  // onChange={handleValidation}
                  inputProps={{ maxLength: 10 }} // Ensure the maximum length is 10
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '20px',
                      width: "350px",
                      height: "60px"
                    },
                  }}
                />
                <Typography className='mt-5' sx={{ fontSize: "10px", }}>This will allow us to verify your identity and grant</Typography>
                <Typography className='mt-1' sx={{ fontSize: "10px", }}> you acess to your account, if you encounter any</Typography>
                <Typography className='mt-1' sx={{ fontSize: "10px", }}>issues or need any assistance,don't hesitate to reach out our support team</Typography>
                <Grid mt={3} container direction="row" justifyContent="center">
                  <Button alignItems="center" className='mt-4' sx={{ fontSize: "15px", fontWeight: "bold", backgroundColor: "#C052E3", color: "#000", borderRadius: "12px", width: "300px" }} autoFocus >
                    Get OTP from your number
                  </Button>
                </Grid>
              </Grid>
              <Grid
                sx={{ backgroundColor: "#f7f4cd" }} >
                <Button autoFocus>
                  close
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
           <Container>
               <Grid mt={2} mb={2} xs={12} container direction="row" justifyContent="space-evenly" alignItems="center" sx={{borderRadius:"20px"}}>
                
                <Grid xs={8} container direction="column" justifyContent="space-evenly" alignItems="center" >
                <Item>
                <Typography sx={{ fontSize: "55px", fontWeight: "bold" }} >Sign in</Typography>
                <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>log into your account</Typography><br />
                
                <Typography className='mt-3' sx={{ fontSize: "20px", }}>To,Login please enter your mobile number</Typography>
                <TextField
                  placeholder='Enter your Mobile Number'
                  className='mt-4'
                  value={phone}
                  onChange={(e)=>{
                    setPhone(e.target.value)
                  }}
                  inputProps={{ maxLength: 10 }} // Ensure the maximum length is 10
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '20px',
                      width: "380px",
                      height: "60px"
                    },
                  }}
                />
                <Typography className='mt-4' sx={{ fontSize: "10px", }}>This will allow us to verify your identity and grant</Typography>
                <Typography className='mt-1' sx={{ fontSize: "10px", }}> you acess to your account, if you encounter any</Typography>
                <Typography className='mt-1' sx={{ fontSize: "10px", }}>issues or need any assistance,don't hesitate to reach out our support team</Typography>
               
                 <Grid mt={5} mb={5} container direction="column" justifyContent="center" alignItems="center">
                 <Button  sx={{ fontSize: "16px", fontWeight: "bold", backgroundColor: "#2d2859", color: "#fff", borderRadius: "12px", width: "330px",height:"50px","&:hover": {backgroundColor: "#2d2859"} }}  >
                    Get OTP from your number
                  </Button>
                  <Typography className='mt-3' sx={{fontSize: "10px",fontWeight:"bold"}}>
                    Create a new account,  <Link onClick={handleRegisterOpen} sx={{color:"#C052E3",textDecoration:"none",cursor:"pointer"}}> register here</Link>
                  </Typography>
                 </Grid>
                <Grid   mb={5} container direction="row" justifyContent="space-around" alignItems="center" >  
                  <Button onClick={BackHome} variant='contained' sx={{backgroundColor: "#2d2859", color: "#fff","&:hover": {backgroundColor: "#2d2859"}}}>
                    Back
                  </Button>
                  <Button variant='contained'  sx={{backgroundColor: "#2d2859", color: "#fff","&:hover": {backgroundColor: "#2d2859"}}} onClick={handleClickOpen} >
                    Try another way
                  </Button>
                </Grid>
                
                </Item>
                </Grid>
                
                {/* <Grid xs={4}>
                   <img src={Sign} alt='' width="100%" height="520px" />
                </Grid> */}
  
               </Grid>
               <Dialog
          
          open={clickOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" sx={{backgroundColor:"#2d2859"}}>
           <Typography className='mt-4' align='center' sx={{fontSize:"30px",color:"#fff"}}>Log into your Account</Typography>
          </DialogTitle>
          <DialogContent sx={{width:"600px",backgroundColor:"#2d2859"}}>
            <DialogContentText>
            <Grid container direction="column" justifyContent="space-evenly" alignItems="center" >
              <Grid className="mt-2">
        <TextField
                  placeholder='Enter your number'
                  className='mt-4'
                  value={number}
                  onChange={(e)=>{
                    setNumber(e.target.value)
                  }} 
                  
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '5px',
                      width: "350px",
                      height: "50px",
                      color: "#fff",
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                  }}
                />
              </Grid>
              <Grid className="mb-3 mt-4">
        <TextField
                  placeholder='Enter your password'
                  
                  value={password}
                  onChange={(e)=>{
                    setPassword(e.target.value)
                  }} 
                  inputProps={{color:"#fff" }} // Ensure the maximum length is 10
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '5px',
                      width: "350px",
                      height: "50px",
                      color: "#fff",
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                  }}
                />
              </Grid>
              <Button
                  variant="contained"
                  type="submit"
                  className=" mt-2"
          onClick={()=>{
            if(!number){
              setOpen(true);
              setColor('error');
              setMsg('Phone Number required');
              return
            }
            if(number.length!==10){
              setOpen(true);
              setColor('error');
              setMsg('Phone Number have 10 number');
              return
            }
            if(!password){
              setOpen(true);
              setColor('error');
              setMsg('Password required');
              return
            }
            
            handlesignin()
          }}
          sx={{backgroundColor:"#f7f4cd", color:"#000","&:hover": {backgroundColor: "#f7f4cd"}}}
              >
                  Log In
              </Button>
              <Typography sx={{color:"#fff",fontSize:"10px",cursor:"pointer"}} className="mt-2">
                  Forget your password?
              </Typography>
          </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{backgroundColor:"#2d2859"}}>
             <Grid mb={3} container direction="column" justifyContent="center" alignItems="center">
             <Button
              variant="primary"
              className=" d-flex align-items-center mb-3"
        sx={{backgroundColor:"#0f40aa",width:"300px","&:hover": {backgroundColor: "#0f40aa"}}}
          >
              <span className="text-center text-white">
        <FacebookIcon sx={{marginRight:"5px"}} /> Continue with Facebook
              </span>
          </Button>
          <Button
              variant="danger"
              className="ezy__signin1_gDWjZETr-btn d-flex align-items-center mb-3"
        sx={{backgroundColor:"#a02d2d",width:"300px","&:hover": {backgroundColor: "#a02d2d"}}}
       
          >
              <span className=" text-center text-white"><GoogleIcon sx={{marginRight:"5px"}}/>Continue with Google</span>
          </Button>
      <Grid container direction="column" justifyContent="center"   alignItems="flex-end" >
             <Button sx={{backgroundColor: "#f7f4cd",color:"#000","&:hover": {backgroundColor: "#f7f4cd"}}} onClick={handleClose} >
              Close
            </Button>
             </Grid>
             </Grid>
            
          </DialogActions>
        </Dialog>
  
        <Dialog
          open={registeropen}
          onClose={handleRegisterClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" sx={{backgroundColor:"#2d2859"}}>
            <Typography sx={{fontSize:"30px",color:"#fff"}}>Register Here !</Typography>
            <Typography sx={{fontSize:"10px",color:"#fff"}}>click your role</Typography>
          </DialogTitle>
          <DialogContent sx={{width:"600px",backgroundColor:"#2d2859"}} >
            <DialogContentText>
             <Grid container direction="column" justifyContent="center" alignItems="center">
             <Item onClick={navigateuser} className='mb-3 mt-4'>
              <Typography  sx={{fontSize:"22px"}}><PersonIcon sx={{fontSize:"25px"}}/> User Registration </Typography>
             </Item>
             <Item onClick={navigatebusiness} className='mb-3'>
              <Typography  sx={{fontSize:"22px"}}><StoreIcon sx={{fontSize:"25px"}}/> Business Registration </Typography>
             </Item>
             <Item onClick={navigatefreelancer} className='mb-3'>
              <Typography   sx={{fontSize:"22px"}}><PersonOutlineIcon sx={{fontSize:"25px"}}/> Freelancer Registration </Typography>
             </Item>
             </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{backgroundColor:"#2d2859"}}>
            {/* <Button autoFocus onClick={handleRegisterClose}>
              Disagree
            </Button> */}
            <Button sx={{color:"#fff"}} onClick={handleRegisterClose} >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={color}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
           </Container>
  
          </>)}
      </>
    )
  }

  export default Signin