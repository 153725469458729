import React,{ useEffect, useState,useRef  } from 'react'
// import {
//   CBadge,
//   CCard,
//   CCardBody,
//   CCardHeader,
//   CCol,
//   CFormCheck,
//   CListGroup,
//   CListGroupItem,
//   CRow,
// } from '@coreui/react'
// import { DocsExample } from 'src/components'
import {Typography,Button,Grid,TextField,Tooltip,Card,CardMedia,CardContent, IconButton} from '@mui/material';
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ListGroups = () => {

const[data,setData]= useState('')
const[advertise,setAdvertise]=useState('')
const [open, setOpen] = React.useState(false);
const [expanded, setExpanded] = React.useState(false);
const[adtitle,setAdtitle]=useState('');
const[adcategory,setAdcategory]=useState('')
const[addescription,setAddescription]=useState('')
const[location,setLocation]=useState('')
const[startdate,setStartdate]=useState('');
const[enddate,setEnddate]=useState('');
const[isapprove,setApprove]=useState('');
const[addimages,setAddimages]=useState('');
const [files, setFiles] = useState([]);
const [msgOpen, setMsgOpen] = useState(false);
const [msg, setMsg] = useState('');
const [color, setColor] = useState('');
const fileInputRef = useRef(null);
const [checked, setChecked] = React.useState(false);



  useEffect(() => {
    getAdvertise()
  }, []); 

const getAdvertise =()=>{
  try{
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch("/api/advertisedetail", requestOptions)
    .then(async (response) => {
      if (response.status === 200 || response.status === 400) {
        return { status_code: response.status, data: await response.json() };
      } else {
        return { status_code: response.status, data: { msg: 'Unexpected Error' } };
      }
    })
    .then((result) => {
      setData(result.data.data)
      console.log(result.data.data,"advertise data")
     })
    .catch(error => console.log('error', error));

  }catch(error){
    console.error(error);
  }
}

const getAdvetiseById =(advertiseid)=>{
  try{
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch(`/api/advertisedata?id=${advertiseid}`,requestOptions)
    .then(async (response) => {
      if (response.status === 200 || response.status === 400) {
        return { status_code: response.status, data: await response.json() };
      } else {
        return { status_code: response.status, data: { msg: 'Unexpected Error' } };
      }
    })
    .then((result) => {
      setAdvertise(result.data.data)
      setAdtitle(result.data.data.adtitle || '');
        setAddescription(result.data.data.addescription || '');
        setAdcategory(result.data.data.adcategory || '');
        setLocation(result.data.data.location || '');
        setStartdate(result.data.data.startdate || '');
        setEnddate(result.data.data.enddate || '');
        setChecked(result.data.data.isapprove || '');
        setAddimages(result.data.data.addimages || '');
      console.log(result.data.data,"advertise data by id")
     })
    .catch(error => console.log('error', error));

  }catch(error){
    console.error(error)
  }
}

const updateAdvertise = async (advertiseid) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const image = await uploadImage();

    const raw = JSON.stringify({
      adtitle: adtitle || advertise.adtitle,
      adcategory: adcategory || advertise.adcategory,
      addescription: addescription || advertise.addescription,
      location: location || advertise.location,
      startdate: startdate || advertise.startdate,
      enddate: enddate || advertise.enddate,
      isapprove: checked || advertise.isapprove,
      addimages: image || advertise.addimages
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`/api/advertise_update?id=${advertiseid}`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: "Unexpected Error" } };
        }
      })
      .then((result) => {
        console.log(result, "advertise updated data");
      })
      .catch((error) => console.log("error", error));
  } catch (error) {
    console.log("error", error);
  }
};

const handleImageChange = (index, event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.type.startsWith('image/')) {
      // Create a preview URL and update the images array
      const newImages = [...addimages];
      newImages[index] = URL.createObjectURL(file); 
      setAddimages(newImages);

      // Store the actual file object for uploading later
      const newFiles = [...files];
      newFiles[index] = file;
      setFiles(newFiles);
    } else {
      alert('Please select a valid image file.');
    }
  }
};

const uploadImage = async () => {
  try {
    const uploadedImageUrls = await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'ae1kvvqp'); // Your Cloudinary preset

        const response = await fetch(
          'https://api.cloudinary.com/v1_1/qikcall/image/upload',
          {
            method: 'POST',
            body: formData,
          }
        );

        const data = await response.json();

        if (data.secure_url) {
          console.log(data.secure_url, 'Image uploaded successfully');
          return data.secure_url; // Return the secure URL from Cloudinary
        } else {
          setMsgOpen(true);
          setColor('error');
          setMsg('Failed to upload image');
          throw new Error('Failed to upload image.');
        }
      })
    );

    // All images uploaded successfully
    setMsgOpen(true);
    setColor('success');
    setMsg('Images uploaded successfully');
    return uploadedImageUrls;
  } catch (error) {
    setMsgOpen(true);
    setColor('error');
    setMsg('Error uploading images');
    throw error;
  }
};




console.log(checked,"log")
  const handleApprove = (event) => {
    setChecked(event.target.checked);
  };


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickOpen = (advertiseid) => {
    setOpen(true);
    getAdvetiseById(advertiseid)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateAdvertise = (advertiseid) => {
    updateAdvertise(advertiseid); 
    handleClose();  
  };

  const handleUploadClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  };


  return (
    <>
    <Grid >
    {Array.isArray(data) && data.length > 0 ? (
    data.map((item, index) => (
      <Card sx={{marginBottom:"12px"}} >
      
      <Accordion mb={4} expanded={expanded === index} onChange={handleChange(index)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          
        >
         <Grid container direction="row" justifyContent="space-between" alignItems="center">
  {/* First Grid (Icon and Title) */}
  <Grid item xs={4} container direction="row" alignItems="center">
    <Typography sx={{ flexShrink: 0 }}>
      Title: {item.adtitle}
    </Typography>
  </Grid>

  {/* Second Grid (Store Information) */}
  <Grid item xs={4}>
    <Typography sx={{ color: 'text.secondary' }}>
      Store: {item.service[0].servicename}
    </Typography>
  </Grid>

  {/* Third Grid (Contact Information) */}
  <Grid item xs={4}>
    <Typography sx={{ color: 'text.secondary' }}>
      Contact: {item.contactnumber}
    </Typography>
  </Grid>
</Grid>

        </AccordionSummary>
        <AccordionDetails >
       
        <Grid container direction="column" justifyContent="space-around" alignItems="flex-start"  >
       <Grid container direction="column" justifyContent="space-around" alignItems="flex-end" >
       <IconButton>
      <EditNoteIcon onClick={()=>{handleClickOpen(item._id)}} color='error' />
    </IconButton>
       </Grid>
         <Typography sx={{color: 'black',fontSize:"13px" }}>
          Description: {item.addescription}
          </Typography>
          <Typography sx={{ color: 'black',fontSize:"13px",marginTop:"8px" }}>Category: {item.adcategory}</Typography>
          <Typography sx={{ color: 'black',fontSize:"13px",marginTop:"8px" }}>Email: {item.emailid}</Typography>
          <Typography sx={{ color: 'black',fontSize:"13px",marginTop:"8px" }}>Aadhar Number: {item.aadharnumber}</Typography>
          <Typography sx={{ color: 'black',fontSize:"13px",marginTop:"8px" }}>Pan Number: {item.pannumber}</Typography>
          <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start">
          <Typography sx={{ color: 'black', fontSize: "13px", marginTop: "10px" }}>
  Start Date: {new Date(item.startdate).toLocaleDateString()}
</Typography>
<Typography sx={{ color: 'black', fontSize: "13px", marginTop: "10px" }}>
  End Date: {new Date(item.enddate).toLocaleDateString()}
</Typography>
          </Grid>
          <Typography sx={{ color: 'black', fontSize: "18px", marginTop: "10px" }}>
  Proof
</Typography>
<Grid container direction="row" justifyContent="space-around" alignItems="center">
  <Grid item xs={3} container direction="column" justifyContent="space-around" alignItems="flex-start">
    <Typography sx={{ color: 'black', fontSize: "13px", marginTop: "10px" }}>
      Aadhar Proof:
    </Typography>
    <img src={item.upload[0].aadhar} alt='aadhar' width="50px" />
  </Grid>

  <Grid item xs={3} container direction="column" justifyContent="space-around" alignItems="flex-start">
    <Typography sx={{ color: 'black', fontSize: "13px", marginTop: "10px" }}>
      Pan Proof:
    </Typography>
    <img src={item.upload[0].pan} alt='pan' width="50px" />
  </Grid>

  <Grid item xs={3} container direction="column" justifyContent="space-around" alignItems="flex-start">
    <Typography sx={{ color: 'black', fontSize: "13px", marginTop: "10px" }}>
      Business Proof:
    </Typography>
    <img src={item.upload[0].proof} alt='business proof' width="50px" />
  </Grid>
</Grid>

         </Grid>
         <Typography sx={{ color: 'black', fontSize: "18px", marginTop: "10px" }}>
  Uploaded Ad
</Typography>

<Grid container direction="column" justifyContent="space-around" alignItems="flex-start">
<img src={item.addimages[0]} alt='aadhar' width="80px" />
</Grid>

         <Typography sx={{ color: 'black', fontSize: "18px", marginTop: "10px" }}>
  Store Address
</Typography>

<Grid container direction="column" justifyContent="space-around" alignItems="flex-start">
<Typography sx={{ color: 'black', fontSize: "13px", marginTop: "10px" }}>
  {item.service[0].addressline1},{item.service[0].addressline2},{item.service[0].area},{item.service[0].city},{item.service[0].pincode},{item.service[0].state}
</Typography>
</Grid>
<Dialog
        
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent sx={{width:"600px"}}>
         <Grid container direction="column" justifyContent="space-around" alignItems="flex-start">
         <TextField
         sx={{marginTop:"15px"}}
         fullWidth
         label="AD Title"
         value={adtitle}
         onChange={(e)=>{
          setAdtitle(e.target.value)
         }}
         />
          <TextField
           sx={{marginTop:"15px"}}
           fullWidth
           label="AD Category"
         value={adcategory}
         onChange={(e)=>{
          setAdcategory(e.target.value)
         }}
         />
          <TextField
           sx={{marginTop:"15px"}}
           fullWidth
           label="AD Description"
         value={addescription}
         onChange={(e)=>{
          setAddescription(e.target.value)
         }}
         />
          <TextField
           sx={{marginTop:"15px"}}
           fullWidth
           label="Location"
         value={location}
         onChange={(e)=>{
          setLocation(e.target.value)
         }}
         />
         <Typography align='left' sx={{fontSize:"18px",marginTop:"15px"}}>
          Uploaded AD:
         </Typography>
         {/* {addimages && addimages.length > 0 ? (
  <Grid container spacing={2} justifyContent="space-around" alignItems="center">
    {addimages.map((image, index) => (
      <Grid item xs={12} sm={6} key={index} container justifyContent="center">
        <img style={{ marginTop: "10px", maxWidth: "100%" }} src={image} alt={`uploaded image ${index}`} />
      </Grid>
    ))}
  </Grid> */}

{/* ) : (
  <p>No images available</p>
)} */}

<Grid item xs={12} sm={6} container direction="column" justifyContent="space-between" alignItems="flex-start">
        <input
          type="file"
          ref={fileInputRef} // Attach ref to the input
          onChange={(event) => handleImageChange(0, event)}
          accept="image/*"
          style={{ display: 'none' }} // Hide the file input
        />
        <Button variant='contained' sx={{backgroundColor:"#1c305c"}} onClick={handleUploadClick}><FileUploadIcon/>Upload</Button>

        {msgOpen && <div style={{ color: color === 'error' ? 'red' : 'green',marginTop:"10px" }}>{msg}</div>}

        {addimages && addimages.length > 0 && (
  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
    {addimages.map((image, index) => (
      <img key={index} src={image} alt={`Preview ${index}`} style={{ width: '200px', height: '200px' }} />
    ))}
  </div>
)}
      </Grid>
<Grid mt={4} container direction="row" justifyContent="flex-end" alignItems="center">
  <Typography>Approve</Typography><Switch
    
      checked={checked}
      onChange={handleApprove}
      inputProps={{ 'aria-label': 'controlled' }}
    />
</Grid>
         </Grid>
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={() => handleUpdateAdvertise(advertise._id)} >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
        
        </AccordionDetails>
      </Accordion>
      </Card>
      ))
    ) : (
      <Typography variant="body2" color="text.secondary">
        No Advertisement available
      </Typography>
    )}
    </Grid>
    </>
  )
}

export default ListGroups
