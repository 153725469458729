import React, { useState, useEffect } from 'react';
import { Container, Grid,IconButton,Typography, Button, Box,Paper, Card,
  CardContent,
  CardMedia,TextField,Alert,Snackbar  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import { useNavigate,useParams,useLocation } from 'react-router-dom';
import './style.css'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Demo from '../../assets/img/image3.jpeg';
import { LocationOn, Phone, CheckCircle, FavoriteBorder } from '@mui/icons-material';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { FaCircleUser } from "react-icons/fa6";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import NavBar from '../navbar';
import Footer from '../footer';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffe0',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  width:"auto",
  height:"auto",
  marginTop:"20px",
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
    
  }),
}));
  


// const DownCard = (props) =>{
//   const names = Array(24).fill('Ambulance Service'); 
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

//   const handleResize = () => {
//     setIsMobile(window.innerWidth <= 425);
//   };

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//   return(
//     <>
//     {isMobile ? (
//     <>
//      <Typography variant="h5"  className=" mt-1 mb-2 title-mob">{props.mobtitle}</Typography>

// <Grid className="frequent-categories-container" >
//   <Grid container className="categories-list-mob">
//     {names.map((category, index) => (
//       <Grid key={index} className="category-item-mob">
//         <Typography variant='subtitle1' sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", }} fontSize={"8px"}>• {category}</Typography>
//       </Grid>
//     ))}
//   </Grid>

// </Grid>
//     </>
//   ): (
//   <>
//   <Grid id="box-width">
//             <Typography variant="h4"  className="title mt-5 mb-2">{props.title}</Typography>
//             </Grid>
//             <Grid xs={12} className="frequent-categories-container" >
//               <Grid container className="categories-list">
//                 {names.map((category, index) => (
//                   <Grid key={index} className="category-item">
//                     <Typography sx={{ fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", fontSize: "10px" }}>• {category}</Typography>
//                   </Grid>
//                 ))}
//               </Grid>
//             </Grid>
//   </>
// )}
//     </>
//   )
// }

const HotelCard = () => {

  

  return (
    <>
    
<Card
      sx={{
        display: 'flex',
        backgroundColor: '#ffffe0',
        borderRadius: '15px',
        padding: '16px',
        maxWidth: 400, // Ensures cards don't take up too much space
      }}
    >
      {/* Image */}
      <CardMedia
        component="img"
        sx={{ width: 150, borderRadius: '10px' }}
        src='https://res.cloudinary.com/qikcall/image/upload/v1725091160/bailg9wjkq8hbfqv7ysi.jpg'
        alt="Hotel"
      />

      {/* Content */}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
        <CardContent sx={{ flex: '1 0 auto', paddingBottom: '8px' }}>
          {/* Star Rating */}
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {[...Array(4)].map((_, index) => (
              <Typography key={index} color="primary">
                ★
              </Typography>
            ))}
            <Typography color="disabled">★</Typography>
          </Box> */}

          {/* Hotel Name */}
          <Typography component="div" variant="h6">
            GRT HOTELS
          </Typography>

          {/* Confirmation & Favorite */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle color="success" sx={{ marginRight: '4px' }} />
            <Typography variant="body2" color="textSecondary">
              Confirmed
            </Typography>
            <IconButton color="default" size="small">
              <FavoriteBorder />
            </IconButton>
          </Box>

          {/* Location */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <LocationOn color="error" />
            <Typography variant="body2" color="textSecondary">
              GRT HOTEL, Palanganatham Madurai
            </Typography>
          </Box>

          {/* Phone */}
          <Button
            variant="outlined"
            startIcon={<Phone />}
            sx={{
              marginTop: '8px',
              borderColor: 'black',
              color: 'black',
            }}
          >
            9075642376
          </Button>
        </CardContent>
      </Box>
    </Card>
   
    </>
    
  );
};

export default function Details() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [value, setValue] = React.useState('');
  const [color,setColor] = useState('')
  const [msg,setMsg] = useState('')
  const [open, setOpen] = React.useState(false);
  const [rate,setRate] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleRateOpen = () => {
    setRate(true);
  };

  const handleRateClose = () => {
    setRate(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  

const{id} = useParams();

  const[cdata,setCdata] = useState('')

  console.log(cdata,"poooooo")

  useEffect(()=>{
    CategoryData();
  },[])

  const CategoryData = () => {
    try{
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      
      fetch(` /api/servicedata?id=${id}`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        setCdata([result.data.data])
        // console.log(result.data.data,"godzillla")
       })
      .catch(error => console.log('error', error));
      
  } catch (error) {
    console.error(error);
  }
  }


  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Get token from URL params or cookies
  const urlToken = params.get('token');
  const cookieToken = Cookies.get('token');
  const token = urlToken || cookieToken;

  const [decodedToken, setDecodedToken] = useState(null);
  const [data, setData] = useState([]);
  console.log(data,"data comming")
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setDecodedToken(decoded);
      } catch (error) {
        console.error('Invalid token:', error);
      }
    } else {
      console.warn('No token found in URL or cookies');
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      handleGetData();
    }
  }, [decodedToken]);
  
  const handleGetData = async () => {
    try {
      const myHeaders = new Headers();
      
      // Check if token exists before appending
      if (token) {
        myHeaders.append("Authorization", token);
      } else {
        console.warn('No token available for Authorization header');
        return;
      }
  
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
  
      fetch(`/api/details?userid=${decodedToken?.user?.id}`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        if (result.status_code === 200) {
          setOpen(true);
          setColor('success');
          setData(result.data.data)
          setMsg(result.data.msg || "Rating Registered");
        } else if (result.status_code === 400) {
          setOpen(true);
          setColor('error');
          setMsg(result.data.msg);
        }
      })
      .catch(error => {
        console.log('error', error);
        setOpen(true);
        setColor('error');
        setMsg(error.response?.data?.message || 'An Error Occurred');
      });

  } catch (error) {
    console.error(error);
    setOpen(true);
    setColor('error');
    setMsg(error.response?.data?.message || 'An Error Occurred');
  }
  };
  

const RegisterRating = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let name;

    if (data && typeof data === 'object' && 'username' in data) {
      name = data.username;
      console.log(name, "Username:");
    } else {
      console.log("Data is either not valid or doesn't contain a username.");
      setOpen(true);
      setColor('warning');
      setMsg('Login to give ratings');
      return;
    }
    

    let storename;
    if (Array.isArray(cdata) && cdata.length > 0) {
      storename = cdata[0].servicename;  // Accessing the nested array's first object's _id
      console.log(storename, "Store ID:");
    } else {
      console.log("cdata is either not an array or is empty.");
      return;
    }

    const raw = JSON.stringify({
      "username": name,
      "storename": storename,
      "rating": value,
      "comment": comment
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("/api/rating", requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: await response.json() };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        if (result.status_code === 200) {
          setOpen(true);
          setColor('success');
          setMsg(result.data.msg || "Rating Registered");
        } else if (result.status_code === 400) {
          setOpen(true);
          setColor('error');
          setMsg(result.data.msg);
        }
      })
      .catch(error => {
        console.log('error', error);
        setOpen(true);
        setColor('error');
        setMsg(error.response?.data?.message || 'An Error Occurred');
      });

  } catch (error) {
    console.error(error);
    setOpen(true);
    setColor('error');
    setMsg(error.response?.data?.message || 'An Error Occurred');
  }
};







const [isFavorite, setIsFavorite] = useState(false); // Track if the item is in the wishlist

// Function to save or remove the wishlist
const saveWishlist = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "storeId": cdata[0]._id, 
      "customerId": data._id, 
      "isfavorite": !isFavorite 
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    console.log(requestOptions,"IIIIIIIIIIIII")

    const response = await fetch("/api/wishlist", requestOptions);
    const result = await response.json();

    if (response.status === 200) {
      setIsFavorite((prev) => !prev); 
      setOpen(true);
      setColor('success');
      console.log(result,"wishlist result")
      setMsg(result.msg || (isFavorite ? "Removed from wishlist" : "Added to wishlist"));
    } else {
      setOpen(true);
      setColor('error');
      setMsg(result.msg || "An error occurred");
    }
  } catch (error) {
    console.error('Error', error);
    setOpen(true);
    setColor('error');
    setMsg('An error occurred');
  }
};

  
  return (
   <>
    {isMobile ? (
    <>
    <NavBar/>
   
      <>
    <Container sx={{marginTop:"50px"}} >
    
    <Grid mt={5}  xs={12} container spacing={1} sx={{ backgroundColor: '#f7f4cd' }}>
    <Grid item xs={4}>
        <Grid container direction="column" spacing={2} alignItems="left">
          <Grid item>
            <img src={Demo} alt="demo" width="100%" height="150px" />
          </Grid>
          <Grid item>
            <img src={Demo} alt="demo" width="100%" height="150px" />
          </Grid>
        </Grid>
      </Grid>

     
      <Grid item xs={4}>
        <Grid container direction="column" spacing={2} alignItems="left">
          <Grid item>
            <img src={Demo} alt="demo" width="100%" height="150px" />
          </Grid>
          <Grid item>
            <img src={Demo} alt="demo" width="100%" height="150px" />
          </Grid>
        </Grid>
      </Grid>

      {/* Third column with one photo occupying two rows */}
      <Grid item xs={4} style={{ position: 'relative' }}>
      <Grid container direction="column"  alignItems="center">
        <Grid item style={{ position: 'relative' }}>
          <img src={Demo} alt="demo" width="100%" height="315px" style={{ display: 'block' }} />
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: background color with opacity
            color: 'white', // Change text color if needed
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            zIndex: 10, // Ensure this is above the image
            padding: '10px', // Optional: padding around the text
          }}>
            + 200 Photos
          </div>
        </Grid>
      </Grid>
    </Grid>
    </Grid>
    </Container>
    <Container >
    <Grid mt={5}   sx={{ border: '1px solid #000', padding: 2,  }}>
      <Grid container  direction="row"   spacing={2}>
        <Grid container  direction="row" justifyContent="space-between"  alignItems="left" item xs={12} md={6} >
          <Grid  item xs={4}>
            <img
              src="https://via.placeholder.com/100" // Replace with actual logo URL
              alt="GRT Hotels"
              style={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid item xs={7} justifyContent="center" alignItems="center">
            <Typography mt={3} sx={{fontSize:"30px"}} fontWeight="bold">
              GRT HOTELS
            </Typography>
            <Typography sx={{fontSize:"20px"}} variant="body2">★★★★☆</Typography>
            <Typography className='mt-2' fontWeight="bold" sx={{fontSize:"10px"}} variant="body2" color="textSecondary">
              <span style={{ marginRight: 8 }}>📍</span>
              GRT HOTEL, Palanganatham Madurai
            </Typography>
            <Grid container spacing={1} alignItems="center" sx={{ marginTop: 1 }}>
              <Grid item>
                <Button variant="outlined" startIcon={<PhoneIcon />}>
                  9075642376
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<ShareIcon />}>
                  Share
                </Button>
              </Grid>
              <Grid item>
                <Button  startIcon={<FavoriteBorderIcon />} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container
  direction="column"
  justifyContent="space-evenly"
  alignItems="flex-start" item xs={12} md={4}>
          <Typography className='mt-4' sx={{fontSize:"15px"}}  variant="body2">
            <strong>GST:</strong> AMJ5678
          </Typography>
          <Typography className='mt-4' sx={{fontSize:"15px"}}  variant="body2">
            <strong>SMS:</strong>
          </Typography>
          <Typography className='mt-4' sx={{fontSize:"15px"}}  variant="body2">
            <strong>Website:</strong> www.grthotel.com
          </Typography>
          <Typography className='mt-4' sx={{fontSize:"15px"}}  variant="body2">
            <strong>Mail id:</strong> grt@com
          </Typography>
          <Typography className='mt-4' sx={{fontSize:"15px"}}  variant="body2">
            <strong>Types of Rooms:</strong> 4
          </Typography>
          <Typography className='mt-4' sx={{fontSize:"15px"}}  variant="body2">
            <strong>Check out:</strong> 24 Hrs
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    </Container>
    </>
    


<Footer/>
    </>
  ):(
  <>
  <NavBar/>
  {Array.isArray(cdata) && cdata.map((datas) => (
  <React.Fragment key={datas._id}> {/* Make sure to add a unique key */}
    <Container>
      <Grid mt={5} sx={{ border: '1px solid #000', padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container alignItems="left">
            <Grid item xs={4}>
              <img
                src="https://via.placeholder.com/100" // Replace with actual logo URL
                alt="GRT Hotels"
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography mt={3} sx={{ fontSize: "30px" }} fontWeight="bold">
                {datas.servicename}
              </Typography>
              <Typography sx={{ fontSize: "20px" }} variant="body2">★★★★☆</Typography>
              <Typography
                className='mt-2'
                fontWeight="bold"
                sx={{ fontSize: "10px" }}
                variant="body2"
                color="textSecondary"
              >
                <span style={{ marginRight: 8 }}>📍</span>
                {`${datas.addressline1}, ${datas.addressline2}, ${datas.area}, ${datas.city}, ${datas.pincode}`}
              </Typography>
              <Grid container spacing={1} alignItems="center" sx={{ marginTop: 1 }}>
                <Grid item>
                  <Button variant="outlined" startIcon={<PhoneIcon />}>
                    {datas.number}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" startIcon={<ShareIcon />}>
                    Share
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={saveWishlist}
                    startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  >
                    {isFavorite ? "Favorited" : "Favorite"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start" item xs={12} md={4}>
            <Typography className='mt-4' sx={{ fontSize: "15px" }} variant="body2">
              <strong>GST:</strong> AMJ5678
            </Typography>
            <Typography className='mt-4' sx={{ fontSize: "15px" }} variant="body2">
              <strong>SMS:</strong>
            </Typography>
            <Typography className='mt-4' sx={{ fontSize: "15px" }} variant="body2">
              <strong>Website:</strong> www.grthotel.com
            </Typography>
            <Typography className='mt-4' sx={{ fontSize: "15px" }} variant="body2">
              <strong>Mail id:</strong> grt@com
            </Typography>
            <Typography className='mt-4' sx={{ fontSize: "15px" }} variant="body2">
              <strong>Types of Rooms:</strong> 4
            </Typography>
            <Typography className='mt-4' sx={{ fontSize: "15px" }} variant="body2">
              <strong>Check out:</strong> 24 Hrs
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>

    {/* Gallery Section */}
    <Container>
      <Card sx={{ width: "100%", backgroundColor: "#f7f4cd", border: "1px solid black", marginTop: "50px", padding: 2 }}>
        <Typography sx={{ fontSize: "18px" }}>Gallery</Typography>
        <Grid mt={5} container spacing={2}>
          {Array.isArray(datas.addimages) && datas.addimages.map((image, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <img src={image} alt={`image-${index}`} width="100%" height="150px" />
            </Grid>
          ))}
        </Grid>
      </Card>
    </Container>
  </React.Fragment>
))}
<Container>
    <Grid mt={5}  container direction="column" justifyContent="center"  alignItems="center" >
      <Card sx={{width:"100%",backgroundColor:"#f7f4cd",border:"1px solid black"}}>
        <Grid mt={3}  container direction="row" justifyContent="space-between"  alignItems="center">
          <Typography sx={{fontSize:"25px",marginLeft:"10px",fontWeight:"bold"}}>
            Customer Reviews
          </Typography>
          <Button onClick={handleRateOpen} variant='contained' sx={{backgroundColor:"#2d2859",marginRight:"10px",'&:hover': {  backgroundColor: "#2d2859", },}} >
            New Comment
          </Button>
          <Dialog
       
        open={rate}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Post Your Experiance"}
        </DialogTitle>
        <DialogContent sx={{width:"600px"}}>
        <Grid mt={4}  container direction="column" justifyContent="flex-start"> 
  <Typography align="left" sx={{fontSize:"30px"}}>
      Rate Your Reviews
    </Typography>
    <Item  >
    
      <Typography className='mt-2'>
        <FaCircleUser/> {data.username}
      </Typography>
     
      
      <Grid mt={3} container direction="column" justifyContent="space-evenly" alignItems="flex-start" >
      <Typography sx={{fontSize:"20px"}} >
      Rate Here: 
      </Typography>
     
      <Rating
      className='mt-2'
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
      </Grid>
      <Grid mt={3} xs={12} container direction="column" justifyContent="space-evenly" alignItems="flex-start">
      <TextField
  id="outlined-textarea"
  label="Comment Your Experience"
  onChange={(e)=>{
    setComment(e.target.value)
  }}
  multiline
  maxRows={4}
  sx={{ width: '300px',height:"80px" }} // Set width to 100% or any other value
/>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
      <Button variant='contained' onClick={()=>{
        // if(!name){
        //   setOpen(true);
        //   setColor('error');
        //   setMsg('username required');
        //   return
        // }
        if(!value){
          setOpen(true);
          setColor('error');
          setMsg('Ratings required');
          return
        }
        if(!comment){
          setOpen(true);
          setColor('error');
          setMsg('Comment Your Experiance');
          return
        }
        RegisterRating()
      }} sx={{backgroundColor:"#2d2859"}}>
        Submit
      </Button>
      </Grid>
    </Item>
    <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={color}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
  </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
          <Button onClick={handleRateClose} autoFocus>
            Close
          </Button>
          </Grid>
        </DialogActions>
      </Dialog>
        </Grid>
        <Grid mt={3} mb={3} container direction="row" justifyContent="space-evenly" alignItems="flex-start">
  {Array.isArray(cdata) && cdata.length > 0 && Array.isArray(cdata[0].ratings) && cdata[0].ratings.map((datas, index) => (
    <Grid  mb={3} item xs={11} key={index} container direction="column" justifyContent="space-evenly" alignItems="flex-start">
      <Paper sx={{ width: "100%", border: "1px solid", padding: "10px" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: "#000", marginLeft: "10px" }} className='mt-2'>
            <FaCircleUser /> {datas.username}
          </Typography>
          <Typography sx={{ fontSize: "12px", marginRight: "10px" }}>
            Commented at {new Date(datas.createdAt).toLocaleDateString()}
          </Typography>
        </Grid>

        <Typography sx={{ marginTop: "10px" }}>
          Ratings:
        </Typography>
        <Rating
          className='mt-2'
          value={datas.rating}
          readOnly
        />

        <Typography sx={{ marginTop: "10px" }}>
          {datas.comment}
        </Typography>
      </Paper>
    </Grid>
  ))}
</Grid>

      </Card>
    </Grid>
    </Container>
<Container>
  
</Container>

    <Container>
      <Typography mt={5} sx={{fontSize:"30px",color:"#000"}} >Related Posts:</Typography>
    <Grid mt={1} mb={3} container spacing={2} justifyContent="center">
        <Grid  item xs={12} sm={6} md={4}>
          <HotelCard  />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <HotelCard />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <HotelCard />
        </Grid>
      </Grid>
    </Container>
    
    <Footer/>
  </>
)}
   </>
  );
}
